import {FC, useEffect, useState} from "react";
import {PageTemplate} from "../../common/components/page-template";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {usePaymentStore} from "../payment-store";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";
import {Card, Container} from "@linkeo.com/ui-lib-react";
import {SubscriptionCard} from "../components/subscription-card";
import {SubscriptionPlan} from "../payment-types";
import {AxiosError} from "axios";
import {useServicesStore} from "../../service/services-store";
import {useNavigate} from "react-router";
import {generatePath} from "react-router-dom";
import {useIntl} from "react-intl";
import {routeSubscriptionConfirmation} from "../../routes";

export const SubscriptionSelectionPage: FC = () => {
    const config = useConfigurationStore();
    const {showSubscriptions, subscriptionsList, selectSubscription} = usePaymentStore();
    const {fetchData, services} = useServicesStore();
    const codeBouton = useCodeBouton();
    const navigate = useNavigate();
    const {locale} = useIntl();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchData()
            .catch((err: AxiosError) => console.error(err))
            .finally(() => setLoading(false))
        return () => setLoading(false)
    }, [fetchData])

    const getMonthlyBestPrice = (plans: SubscriptionPlan[]): number => {
        const validPlans = plans.filter(plan => plan.active && !!plan.price);
        if (validPlans.length === 0) {
            return 0
        }
        const pricesPerMonth = validPlans.map(plan => {
            return plan.price! / plan.numberOfMonths
        })
        return Math.min(...pricesPerMonth)
    }

    return <PageTemplate isLoading={loading} configuration={config} codeBouton={codeBouton}
                         showSubscriptions={showSubscriptions}>
        <Container size={'lg'}>
            <Card style={{padding: 0}}>
                {subscriptionsList.map(subscription =>
                    <SubscriptionCard
                        key={subscription.id}
                        currency={subscription.currency}
                        price={getMonthlyBestPrice(subscription.plans)}
                        subscriptionFees={subscription.subscriptionFees}
                        title={subscription.name}
                        services={subscription.services.map(s => services.find(el => el.id === s.id)?.title)}
                        subscriptionConditions={subscription.subscriptionConditions}
                        unsubscriptionConditions={subscription.unsubscriptionConditions}
                        otherSubscriptionConditions={subscription.otherSubscriptionConditions}
                        onSelect={() => {
                            selectSubscription(subscription);
                            navigate(generatePath(routeSubscriptionConfirmation, {codeBouton, locale}))
                        }}
                    />)}
            </Card>
        </Container>
    </PageTemplate>
}