import React, {FC, FormEvent, Reducer, useReducer, useState} from 'react';
import {Button, DatePicker, Flex, Input, ReducerObject, ReducerObjectType, useToaster} from '@linkeo.com/ui-lib-react';
import {GetErrorsRegisterForm} from '../authentification.validator';
import {ErrorsBuilder} from '../../../common/errors-builder';
import {ToggleReducer} from '../../../common/misc-util';
import {useAuthStore} from '../auth-store';
import { useIntl, FormattedMessage } from 'react-intl';
import {PhoneNumberInput} from '../../components/phonenumber/input';
import {useLocale} from "../../../common/providers/intl.provider";
import { format } from 'date-fns-tz';

interface CustomerRegister {
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  phone: string,
  birthDate: string
}

export const RegisterForm: FC = () => {
  const toast = useToaster();
  const intl = useIntl();
  const {locale} = useLocale();
  const {register} = useAuthStore();
  const [getLoading, setLoading] = useState<boolean>(false);
  const [getFormControl, setFormControl] = useReducer<ReducerObjectType<CustomerRegister>>(ReducerObject, {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phone: '',
    birthDate: ''
  });
  const [showPassword, toggleShowPassword] = useReducer<Reducer<boolean, void>>(ToggleReducer, false);
  const [errors, setErrors] = useState<ErrorsBuilder<CustomerRegister>>(new ErrorsBuilder<CustomerRegister>());
  const lang = locale.split('-')[1].toLowerCase();
  const emitSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (getLoading) {
      return;
    }
    const checkError = GetErrorsRegisterForm(getFormControl);
    setErrors(checkError);
    if (checkError.hasError()) {
      return
    }
    setLoading(true);
    register(getFormControl)
        .then(() => {
      toast(intl.formatMessage({id: 'registerFormToastConfirm', defaultMessage: 'Votre compte a été créé avec succès'}), {
        type: 'success',
        outlined: true,
        icon: "award-outline"
      })
    }).catch((err) => {
      if (err.response?.status === 409) {
        toast(intl.formatMessage({id: 'registerError', defaultMessage: 'Un compte avec cette adresse email existe déjà'}))
      } else if (err.response?.status === 400) {
        toast(intl.formatMessage({id: 'telephoneError', defaultMessage: 'Le numéro de téléphone est invalide'}))
      } else {
        toast(intl.formatMessage({id: 'toastMessageError', defaultMessage: 'Une erreur est survenue'}))
      }
    }).finally(() => setLoading(false))
  }
  return <form onSubmit={emitSubmit}>
    <Input hasError={errors.hasError('firstName')} name={"given-name"} label={intl.formatMessage({id : 'accountEditFirstNameLabel', defaultMessage: 'Prénom'})} required
           value={getFormControl.firstName}
           onChange={firstName => setFormControl({merge: {firstName}})}/>
    <Input hasError={errors.hasError('lastName')} name={"family-name"} label={intl.formatMessage({id : 'accountEditLastNameLabel', defaultMessage: 'Nom'})} required
           value={getFormControl.lastName}
           onChange={lastName => setFormControl({merge: {lastName}})}/>
    <DatePicker 
      locale={lang}
      label={intl.formatMessage({id: 'accountEditBirthDateLabel', defaultMessage: 'Date de naissance'})} required
      value={getFormControl.birthDate ? new Date(getFormControl.birthDate) : null}
      onChange={birthDate => {
        if(birthDate){
          setFormControl({ merge: { birthDate: format(birthDate, 'yyyy-MM-dd') } })
        }
      }}
      placeholder={intl.formatMessage({id: 'DateFormatPlaceholder', defaultMessage: 'JJ/MM/AAAA'})}
    />
    <Input hasError={errors.hasError('email')} name={"email"} type={'email'}
           label={intl.formatMessage({id : 'PasswordFormEmailLabel', defaultMessage: 'Adresse e-mail'})} required
           value={getFormControl.email}
           onChange={email => setFormControl({merge: {email}})} icon={"email-outline"}/>
    <PhoneNumberInput
        hasError={errors.hasError('phone')} name={'telephone'} type={'tel'}
        label={intl.formatMessage({id: 'accountEditMobileLabel', defaultMessage: 'Téléphone mobile'})} required
        value={getFormControl.phone}
        onChange={phone => setFormControl({ merge: { phone } })}
        zone={lang} 
      />
    <Input hasError={errors.hasError('password')} name={"new-password"}
           type={showPassword ? 'text' : 'password'}
           label={intl.formatMessage({id : 'PasswordFormPasswordLabel', defaultMessage: 'Mot de passe'})} required
           value={getFormControl.password} onChange={password => setFormControl({merge: {password}})}
           icon={showPassword ? "eye2-off-outline" : "eye2-outline"} onIconClick={toggleShowPassword}/>

    <Flex justifyContent={['center']}>
      <Button id={'register_form_button'} type={'submit'} colorType={'outlineSecondary'}
              loading={getLoading}>
              <FormattedMessage id='registerFormButtonSubmit' defaultMessage='Créer un compte'/>
      </Button>
    </Flex>
  </form>
}
