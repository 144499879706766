import React, {FC, useEffect, useState} from 'react';
import {Flex, InOut, Svg} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";
import {BlockCategory} from "../components/service/block-category";
import {useBookingStore} from '../booking-store';
import {useServicesStore} from '../../service/services-store';
import {routeDateSelection} from '../../routes';
import {useConfigurationStore} from "../../configuration/configuration-store";
import {useAuthStore} from "../../customer/authentification/auth-store";
import {AuthenticationPage} from "../../customer/authentification/pages/authentication-page";
import {FormattedMessage, useIntl} from 'react-intl';
import {LinkPrimary} from '../../common/components/link-primary';
import {ServiceSelectionSection} from '../components/service/service-selection-section';
import {PageTemplate} from '../../common/components/page-template';
import {Service} from '../book.types';
import {CreateDebounce} from '../../common/misc-util';
import {delayService} from '../../common/config/debounce-time';
import {generatePath, useNavigate} from "react-router";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";
import {usePaymentStore} from "../../subscription/payment-store";
import {useAccountStore} from "../../customer/account/account-store";

const BarFix = styled.div`
  position: fixed;
  bottom: 0;
  background: rgba(247, 247, 247, 0.8);
  padding: 30px 0;
  width: 100%;
`;
export const ServiceSelectionPage: FC = () => {
    const {booking, calculSelectedCount, setServices, cancelPaymentBooking} = useBookingStore();
    const config = useConfigurationStore();
    const {appointment: {allowAnonymous, paymentActive}} = config
    const {showSubscriptions} = usePaymentStore();
    const {authData} = useAuthStore();
    const intl = useIntl();
    const theme = useTheme();
    const navigate = useNavigate();
    const {categories, fetchData, services} = useServicesStore();
    const {loadSubscriptions, subscriptions} = useAccountStore()
    const [loading, setLoading] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [subscriptionsServices, setSubscriptionsServices] = useState<Service[]>([])
    const [selectedServices, setSelectedServices] = useState<Service[]>(booking.appointments.filter(f => f.selected && !f.subscription).map(ap => ap.service) || [])
    const codeBouton = useCodeBouton();

    useEffect(() => {
        cancelPaymentBooking();
        fetchData().catch((err) => console.error(err))
    }, [fetchData, cancelPaymentBooking])

    useEffect(() => {
        if (authData) {
            loadSubscriptions()
                .catch(e => console.error(e))
                .finally(() => setIsLoading(false))
        } else {
            setIsLoading(false)
        }
        return () => setIsLoading(false)
    }, [authData, loadSubscriptions])

    useEffect(() => {
        const subscriptionsServicesIds = Array.from(new Set(subscriptions.flatMap(subscription => subscription.services.map(el => el.id))))
        setSubscriptionsServices(services.filter(service => subscriptionsServicesIds.includes(service.id)))
    }, [services, subscriptions])

    if (!allowAnonymous && !authData) {
        return <AuthenticationPage/>
    }

    const debounceSetSelectedServices = CreateDebounce((services: Service[]) => {
        setServices(services).finally(() => setLoading(false));
    }, delayService)

    const selectService = (services: Service[]) => {
        setSelectedServices(services);
        debounceSetSelectedServices(services).catch(err => console.error(err))
    };

    const onServiceSelection = (service: Service) => {
        setLoading(true);
        const find = selectedServices.find(el => el.id === service.id)
        if (find) {
            selectService(selectedServices.filter(el => el.id !== find.id))
        } else {
            selectService([...selectedServices, service])
        }
    }

    const onSubscriptionSelectedService = async (service: Service) => {
        setIsLoading(true);
        try {
            await setServices([service], true)
            navigate(generatePath(routeDateSelection, {codeBouton, locale: intl.locale}))
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    const minValue = (services: Service[]) => {
        let minPositiveValue = null;
        for (const service of services) {
            const value = service.duration;
            if (value > 0 && (minPositiveValue === null || value < minPositiveValue)) {
                minPositiveValue = value;
            }
        }
        return minPositiveValue === null ? 0 : minPositiveValue;
    }

    return <PageTemplate configuration={config} isLoading={!categories || isLoading} codeBouton={codeBouton}
                         showSubscriptions={showSubscriptions}>
        {categories ? <ServiceSelectionSection title={intl.formatMessage({
            id: 'servicesSelectionSubtitle',
            defaultMessage: 'Choisissez et sélectionnez la(les) prestation(s) pour lesquelle(s) vous souhaitez prendre RDV'
        })}>
            {authData && subscriptionsServices.length > 0 && <BlockCategory
                isUniqueSelection={true}
                subscription={true}
                categoryTitle={intl.formatMessage({
                    id: 'serviceSelectionPageBlockCategorySubscriptionTitle',
                    defaultMessage: 'Mes prestations d’abonnement'
                })}
                services={subscriptionsServices}
                minPrice={0}
                maxDuration={Math.max(...subscriptionsServices.map(el => el.duration))}
                minDuration={minValue(subscriptionsServices)}
                paymentActive={false}
                currency={config.currency}
                onSelectedService={onSubscriptionSelectedService}
                selectedServices={[]}
                selectedCount={0}
            />}
            {Object.keys(categories).sort().map((cat) => <BlockCategory
                onSelectedService={onServiceSelection}
                selectedServices={selectedServices}
                key={cat} categoryTitle={cat}
                services={categories[cat].services}
                minPrice={categories[cat].minPrice}
                maxDuration={categories[cat].maxDuration}
                minDuration={categories[cat].minDuration}
                selectedCount={calculSelectedCount(categories[cat].services)}
                description={categories[cat].description}
                currency={config.currency}
                paymentActive={paymentActive}
            />)}
        </ServiceSelectionSection> : null}
        <div style={{marginBottom: 90}}/>
        <InOut show={selectedServices.length > 0}>
            <BarFix>
                <Flex justifyContent={['center']} className={'service_selection_button'}>
                    <LinkPrimary to={generatePath(routeDateSelection, {codeBouton, locale: intl.locale})}>
                        {loading ? <Svg animatedIcon={"spinner"} height={'24px'} stroke={theme.colors.light}/>
                            : <FormattedMessage
                                id='servicesSelectionButtonConfirmTake'
                                defaultMessage={'Prendre RDV ({count,plural, =1 {1 prestation} other {# prestations}})'}
                                values={{count: selectedServices.length}}/>}
                    </LinkPrimary>
                </Flex>
            </BarFix>
        </InOut>
    </PageTemplate>

}
