import {
    routeGiftCard,
    routeGiftCardConfirmation,
    routeGiftCardDisplay,
    routeGiftCardPaymentValidation,
    routeGiftCardSummary
} from "../../routes";
import React, {FC} from "react";
import {useAuthStore} from "../../customer/authentification/auth-store";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {AuthenticationPage} from "../../customer/authentification/pages/authentication-page";
import {Route, Routes} from "react-router-dom";
import {GiftCardSelectionPage} from "./gift-card-selection-page";
import {GiftCardConfirmationPage} from "./gift-card-confirmation-page";
import {GiftCardPaymentValidation} from "./gift-card-payment-validation";
import {GiftCardDisplayPage} from "./gift-card-display-page";
import {ServicesStore} from "../../service/services-store";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";
import { RegisterPhonePage } from "../../customer/authentification/pages/register-phone-page";
import { GiftcardSummaryPage } from "./gift-card-summary-page";
import {usePaymentStore} from "../../subscription/payment-store";

const getLocalPath = (fullPath: string): string => {
    const re = new RegExp(`^${routeGiftCard}`);
    return fullPath.replace(re, '');
}

export const IndexPage: FC = () => {
    const {authData} = useAuthStore();
    const config = useConfigurationStore();
    const {appointment: {allowAnonymous}} = useConfigurationStore();
    const {showSubscriptions} = usePaymentStore();
    const codeBouton = useCodeBouton();

    // Si le numero de telephone s'il n'existe pas
    if(authData && (!authData.phone || !authData.birthDate)){
        return <RegisterPhonePage />
    }

    if (!allowAnonymous && !authData) {
        return <AuthenticationPage/>
    }
    return <ServicesStore giftable={true} codeBouton={codeBouton} token={authData?.accessToken.token} config={config} showSubscriptions={showSubscriptions}>
        <Routes>
            <Route path={getLocalPath(routeGiftCard)} element={<GiftCardSelectionPage/>}/>
            <Route path={getLocalPath(routeGiftCardConfirmation)} element={<GiftCardConfirmationPage/>}/>
            <Route path={getLocalPath(routeGiftCardPaymentValidation)} element={<GiftCardPaymentValidation/>}/>
            <Route path={getLocalPath(routeGiftCardSummary)} element={<GiftcardSummaryPage />} />
            <Route path={getLocalPath(routeGiftCardDisplay)} element={<GiftCardDisplayPage/>}/>
        </Routes>
    </ServicesStore>
}
