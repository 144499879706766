import { Route, Routes } from "react-router-dom";
import { AccountPage } from "./page-account";
import { GiftCardsListingPage } from "./page-gift-cards";
import { useAuthStore } from "../../authentification/auth-store";
import { AuthContainer } from "../../authentification/components/auth-container";
import { Container } from "@linkeo.com/ui-lib-react";
import { AppointmentsListingPage } from "./page-appointments-listing";
import { AccountProvider } from "../account-store";
import { useCodeBouton } from "../../../common/providers/code-bouton-provider";
import { AxiosError } from "axios";
import { PageTemplate } from "../../../common/components/page-template";
import { useConfigurationStore } from '../../../configuration/configuration-store';
import { AuthenticationPage } from "../../authentification/pages/authentication-page";
import { RegisterPhonePage } from "../../authentification/pages/register-phone-page";
import { usePaymentStore } from "../../../subscription/payment-store";
import { SubscriptionsListingPage } from "./subscriptions-listing-page";
import { ServicesStore } from "../../../service/services-store";

export const PageIndex = () => {
    const { authData, logOut } = useAuthStore();
    const config = useConfigurationStore();
    const { showSubscriptions } = usePaymentStore();
    const codeBouton = useCodeBouton();

    if (!authData) {
        return <AuthenticationPage />
    }

    // Si le numero de telephone s'il n'existe pas
    if (!authData.phone || !authData.birthDate) {
        return <RegisterPhonePage />
    }

    const onError = (err: any) => {
        if (err instanceof AxiosError && (err.response?.status === 401 || err.response?.status === 403)) {
            logOut();
        }
    }
    return <PageTemplate configuration={config} authData={authData} codeBouton={codeBouton} showSubscriptions={showSubscriptions}>
        <ServicesStore config={config} showSubscriptions={showSubscriptions} codeBouton={codeBouton}>
            <AccountProvider codeBouton={codeBouton} token={authData?.accessToken.token} onError={onError}>
                <Container size={'lg'}>
                    {authData ? <Routes>
                        <Route path={''} element={<AccountPage />} />
                        <Route path={`/gift-cards`} element={<GiftCardsListingPage />} />
                        <Route path={`/appointments`} element={<AppointmentsListingPage />} />
                        <Route path={`/subscriptions`} element={<SubscriptionsListingPage />} />
                    </Routes> : <AuthContainer />}
                </Container>
            </AccountProvider>
        </ServicesStore>
    </PageTemplate>
}
