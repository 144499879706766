import React, {FC, useEffect, useState} from "react";
import {PageTemplate} from "../../common/components/page-template";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {useAuthStore} from "../../customer/authentification/auth-store";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";
import {usePaymentStore} from "../payment-store";
import {generatePath, Navigate, useNavigate} from "react-router";
import {routeSubscription} from "../../routes";
import {FormattedMessage, useIntl} from "react-intl";
import {Container, PageTitle, useToaster} from "@linkeo.com/ui-lib-react";
import {SubscriptionEntrySection} from "../components/subscription-entry-section";
import {PaymentIntent, SubscribePayload} from "../payment-types";
import {CardAuthentification} from "../../customer/authentification/components/card-authentification";
import {SubscriptionValidationSection} from "../components/subscription-validation-section";
import {useParams} from "react-router-dom";

export const SubscriptionConfirmationPage: FC = () => {
    const config = useConfigurationStore();
    const {authData, logOut} = useAuthStore();
    const codeBouton = useCodeBouton();
    const {locale} = useIntl();
    const {subscriptionId, planId} = useParams<{ subscriptionId: string, planId: string }>()
    const toast = useToaster();
    const intl = useIntl();
    const navigate = useNavigate();
    const {
        showSubscriptions,
        selectedSubscription,
        createSubscription,
        selectSubscription,
        subscriptionsList,
        selectedPlanId,
        setSelectedPlanId

    } = usePaymentStore();
    const [loading, setLoading] = useState<boolean>(false);
    const [form, setForm] = useState<SubscribePayload>({
        subscriberId: authData?.id || '',
        firstName: authData?.firstName,
        lastName: authData?.lastName,
        locale: selectedSubscription?.locale || locale,
        email: authData?.email || '',
        phone: authData?.phone,
        planId: selectedPlanId.length > 0 && selectedSubscription?.plans.find(el => el.id === selectedPlanId) ? selectedPlanId : selectedSubscription?.plans.sort((a, b) => b.numberOfMonths - a.numberOfMonths)[0].id || ''
    });
    const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(null);

    useEffect(() => {
        if (subscriptionId && planId) {
            const subscription = subscriptionsList.find(el => el.id === subscriptionId);
            if (subscription) {
                selectSubscription(subscription)
                setForm((prevState) => {
                    return {...prevState, locale: subscription.locale||locale, planId}
                })
            } else {
                navigate(generatePath(routeSubscription, {codeBouton, locale: intl.locale}))
            }
        }
    }, [codeBouton, intl.locale, locale, navigate, planId, selectSubscription, subscriptionId, subscriptionsList]);

    if ((!config.appointment.allowAnonymous && !authData) || (!selectedSubscription && !subscriptionId && !planId)) {
        return <Navigate to={generatePath(routeSubscription, {codeBouton, locale: intl.locale})}/>;
    }

    const getFrequencyTitle = (number: number): string => {
        switch (number) {
            case 12:
                return intl.formatMessage({id: 'subscriptionFrequencyTitleYearly', defaultMessage: '1 an'})
            case 3:
                return intl.formatMessage({id: 'subscriptionFrequencyTitleQuaterly', defaultMessage: 'Trimestriel'})
            case 1:
            default:
                return intl.formatMessage({id: 'subscriptionFrequencyTitleMonthly', defaultMessage: 'Mensuel'})
        }
    }

    const onSubscribeCreate = async (form: SubscribePayload) => {
        setLoading(true)
        try {
            const response = await createSubscription(selectedSubscription?.id||'', form);
            setPaymentIntent(response)
        } catch (e) {
            console.error(e)
            toast(intl.formatMessage({id: 'toastMessageError', defaultMessage: 'Une erreur est survenue'}))
        } finally {
            setLoading(false)
        }
    }

    return <PageTemplate configuration={config} isLoading={!selectedSubscription}
                         authData={authData === null ? undefined : authData}
                         codeBouton={codeBouton} showSubscriptions={showSubscriptions}>
        <Container size={'lg'}>
            <PageTitle>
                <FormattedMessage id={'subscriptionConfirmationPageTitle1'} defaultMessage={'1. Votre abonnement'}/>
            </PageTitle>
            <SubscriptionEntrySection
                form={form}
                planIdIsSelected={!!planId}
                readOnly={!!paymentIntent}
                onPlanChange={(planId) => {
                    setForm({...form, planId});
                    setSelectedPlanId(planId);
                }}
                plans={selectedSubscription?.plans.sort((a, b) => b.numberOfMonths - a.numberOfMonths)||[]}
                name={selectedSubscription?.name||''}
                subscriptionFees={selectedSubscription?.subscriptionFees}
                getFrequencyTitle={getFrequencyTitle}
                onSubscribeSubmit={() => onSubscribeCreate(form)}
                currency={selectedSubscription?.currency||''}/>
            <CardAuthentification
                authData={authData}
                titleName={intl.formatMessage({id: 'GCbookingSummaryTitle2', defaultMessage: '2. Identification'})}
                subtitle={intl.formatMessage({id: 'bookingSummaryAuthSubtitle', defaultMessage: 'Bienvenue !'})}
                buttonLabel={intl.formatMessage({
                    id: 'bookingSummaryAuthCaptionLogout',
                    defaultMessage: 'Ce n’est pas votre compte ?'
                })}
                onClickLogOut={logOut}/>
            {authData
                ? <SubscriptionValidationSection
                    name={selectedSubscription?.name||''}
                    frequency={getFrequencyTitle(selectedSubscription?.plans.find(el => el.id === form.planId)?.numberOfMonths || 1)}
                    planPrice={selectedSubscription?.plans.find(el => el.id === form.planId)?.price || 0}
                    currency={selectedSubscription?.currency||''}
                    subscriptionFees={selectedSubscription?.subscriptionFees}
                    loading={loading}
                    codeBouton={codeBouton}
                    locale={locale}
                    paymentIntent={paymentIntent}
                    publicKey={config.publicKey || ""}
                    total={(selectedSubscription?.plans.find(el => el.id === form.planId)?.price || 0) + (selectedSubscription?.subscriptionFees || 0)}
                />
                : null}
        </Container>
    </PageTemplate>
}