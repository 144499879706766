import {Total} from "../../common/commons.types";

export const giftCardValid = 'valid'
export const giftCardInvalid = 'invalid'

export type GiftCardStatus = typeof giftCardValid | typeof giftCardInvalid

export interface giftCardCodesStatus {
    code: string;
    status?: GiftCardStatus;
    total?: Total
}

export interface Agenda {
    id: string;
    name: string;
}

interface Choice {
    id: string;
    label: string;
    price?: number;
    quantity?: number;
}

export interface Option {
    id: string;
    name: string;
    type: string;
    min: number;
    max: number | null;
    countable: boolean;
    extraCharge?: number;
    maxExtra?: number;
    maxFree?: number;
    choices: Choice[]
}

interface Service {
    agendas: Agenda[];
    description: string;
    duration: number;
    id: string;
    price: number;
    maxSpotsPerBookEntry: number;
    title: string;
    downPayment?: number,
}

export interface PartialBookEntry {
    service: Service;
    spots: number;
    agenda?: Agenda | null;
    selected: boolean;
    options?: Option[];
    optionsTotal?: number;
    subscription?: boolean
}
