import {FC} from 'react';
import {Card, CardTitle, Container, PageTitle} from '@linkeo.com/ui-lib-react';
import {ResetPasswordForm} from '../components/reset-password-form';
import { FormattedMessage } from 'react-intl';
import { useConfigurationStore } from '../../../configuration/configuration-store';
import { PageTemplate } from '../../../common/components/page-template';
import {useCodeBouton} from "../../../common/providers/code-bouton-provider";
import {usePaymentStore} from "../../../subscription/payment-store";

export const ResetPasswordPage: FC = () => {

  const config = useConfigurationStore();
  const codeBouton = useCodeBouton();
  const {showSubscriptions} = usePaymentStore();

  return <PageTemplate configuration={config} codeBouton={codeBouton} showSubscriptions={showSubscriptions}>
    <Container size={"lg"}>
      <PageTitle>
        <FormattedMessage id='resetPasswordPageTitle' defaultMessage='Mon compte'/>
      </PageTitle>
      <Card>
        <CardTitle style={{textAlign: 'center'}}>
        <FormattedMessage id='resetPasswordPageCardTitlle' defaultMessage='Réinitialiser le mot de passe'/>
        </CardTitle>
        <ResetPasswordForm/>
      </Card>
    </Container>
  </PageTemplate>
}