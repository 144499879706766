import React, {FC, ReactNode} from 'react';
import {BaseButton, Card, Col, Container, Row, SpanBody2, SpanLink, SpanSubtitle1} from '@linkeo.com/ui-lib-react';
import {BookEntryCard} from './book-entry-card';
import {PageTitleWithCircleCount} from '../../common/components/page-title-with-circle-count';
import {CircledSvg} from "../../common/components/circled-svg";
import {useNavigate} from "react-router";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {useTheme} from "styled-components";
import {PartialBookEntry} from "./booking-types";
import {InfoPrice} from '../../common/components/info-price';

interface BookEntriesReadOnlyProps {
    appointmentsCount: number,
    totalPrice: number,
    currency: string,
    optionsTotal?: number;
    optionsCount?: number;
    totalToPay?: number;
    appointmentDetailsPath: string;
    isSubscription: boolean
}

export const BookEntriesReadOnly: FC<BookEntriesReadOnlyProps> = ({
                                                                      appointmentsCount,
                                                                      totalPrice,
                                                                      currency,
                                                                      optionsTotal,
                                                                      optionsCount,
                                                                      totalToPay,
                                                                      appointmentDetailsPath,
                                                                      isSubscription
                                                                  }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    return <Card>
        <Row>
            <Col>
                <CircledSvg icon={'calendar-outline'}/>
            </Col>
            <Col>
                <div>
                    <SpanBody2>
                        <FormattedMessage defaultMessage='{count,plural, =1 {1 prestation} other {# prestations}}'
                                          id='bookingSummaryPrestationCount' values={{count: appointmentsCount}}/>
                    </SpanBody2>
                </div>
                {optionsCount && optionsCount !== 0 ? <div>
                    <SpanSubtitle1>
                        <FormattedMessage defaultMessage='{count,plural, =1 {1 option} other {# options}}'
                                          id='bookingSummaryOptionsCount' values={{count: optionsCount}}/>
                    </SpanSubtitle1>
                </div> : null}
                <BaseButton onClick={() => navigate(appointmentDetailsPath)} style={{marginTop: '16px'}}>
                    <SpanLink><FormattedMessage id='bookingSummaryButtonShowDetails'
                                                defaultMessage='Afficher le Détail'/></SpanLink>
                </BaseButton>
            </Col>
            <Col style={{marginLeft: "auto", textAlign: 'end'}}>
                {totalPrice > 0 && !isSubscription && <div>
                    <InfoPrice currency={currency}/>
                    <SpanBody2>
                        <FormattedNumber value={totalPrice} style={`currency`} currency={currency}/>
                    </SpanBody2>
                </div>}
                {isSubscription && <SpanBody2>
                    <FormattedMessage
                        id={'bookingConfirmationSubscriptionServiceSelectedValueLabel'}
                        defaultMessage={'inclus dans l’abonnement'}/>
                </SpanBody2>}
                {optionsTotal && optionsTotal !== 0 ? <div>
                    <InfoPrice currency={currency}/>
                    <SpanBody2 style={{color: theme.colors.grey40}}>
                        <FormattedNumber value={optionsTotal} style={`currency`} currency={currency}/>
                    </SpanBody2>
                </div> : null}
                {totalToPay && !isSubscription ? <div>
                    <br/>
                    <SpanBody2>
                        <FormattedMessage id={'bookEntryCardDownPaymentInfo'}
                                          defaultMessage={'Acompte(s) à régler maintenant '}/>
                    </SpanBody2>
                    <InfoPrice currency={currency}/>
                    <SpanBody2>
                        <FormattedNumber value={totalToPay} style={'currency'} currency={currency}/>
                    </SpanBody2>
                </div> : null}
            </Col>
        </Row>
    </Card>
}

interface BookEntriesEditorProps {
    appointments: Array<PartialBookEntry>
    onAppointmentsChange?: (appointments: Array<PartialBookEntry>) => void;
    currency: string;
    enableSwitch?: boolean;
    allowAgendaSelection: boolean;
    isSubscription: boolean
}

export const BookEntriesEditor: FC<BookEntriesEditorProps> = ({
                                                                  appointments,
                                                                  onAppointmentsChange,
                                                                  currency,
                                                                  enableSwitch,
                                                                  allowAgendaSelection,
                                                                  isSubscription
                                                              }) => {
    const updateBookingAndFetchAvailabilities = (bookEntry: PartialBookEntry, index: number) => {
        if (onAppointmentsChange) {
            const clone = appointments.slice();
            clone.splice(index, 1, bookEntry)
            onAppointmentsChange(clone);
        }
    }

    return <>{
        appointments.map((bookEntry, i) => <BookEntryCard
                agendas={bookEntry.service.agendas}
                maxSpots={bookEntry.service.maxSpotsPerBookEntry}
                serviceDuration={bookEntry.service.duration}
                servicePrice={bookEntry.service.price}
                serviceTitle={bookEntry.service.title}
                total={bookEntry.optionsTotal || 0}
                key={i}
                currency={currency}
                allowAgendaSelection={allowAgendaSelection}
                bookEntry={bookEntry}
                onChange={(b) => {
                    const be = {...bookEntry, ...b};
                    updateBookingAndFetchAvailabilities(be, i)
                }}
                enableSwitch={enableSwitch}
                isSubscription={isSubscription}
            />
        )}</>
}

interface BookEntriesSectionProps {
    readOnly: boolean;
    currency: string;
    totalPrice: number;
    appointments: PartialBookEntry[];
    onAppointmentsChange?: (appointments: Array<PartialBookEntry>) => void;
    selectedAppointmentsCount: number;
    enableSwitch?: boolean;
    optionsCount?: number;
    optionsTotal?: number;
    allowAgendaSelection: boolean;
    appointmentDetailsPath: string;
    totalToPay?: number;
    isSubscription: boolean;
}

export const BookEntriesSection: FC<BookEntriesSectionProps> = ({
                                                                    appointments,
                                                                    readOnly,
                                                                    currency,
                                                                    totalPrice,
                                                                    selectedAppointmentsCount,
                                                                    onAppointmentsChange,
                                                                    enableSwitch,
                                                                    optionsCount,
                                                                    optionsTotal,
                                                                    allowAgendaSelection,
                                                                    totalToPay,
                                                                    appointmentDetailsPath,
                                                                    isSubscription
                                                                }) => {

    let content: ReactNode;

    if (readOnly) {
        content = <BookEntriesReadOnly
            appointmentDetailsPath={appointmentDetailsPath}
            currency={currency}
            appointmentsCount={appointments.length}
            totalPrice={totalPrice}
            optionsCount={optionsCount}
            optionsTotal={optionsTotal}
            totalToPay={totalToPay}
            isSubscription={isSubscription}
        />
    } else {
        content = <BookEntriesEditor
            appointments={appointments}
            onAppointmentsChange={onAppointmentsChange}
            currency={currency}
            enableSwitch={enableSwitch}
            allowAgendaSelection={allowAgendaSelection}
            isSubscription={isSubscription}
        />
    }
    return <Container size={'lg'}>
        <PageTitleWithCircleCount count={selectedAppointmentsCount}>
            <FormattedMessage id='bookEntrySelectionTitle' defaultMessage='1. Vos préstations'/>
        </PageTitleWithCircleCount>
        {content}
    </Container>
}
