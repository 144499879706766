import {FC, useEffect} from 'react';
import { generatePath, useNavigate } from 'react-router';
import { useBookingStore } from '../../../booking/booking-store';
import { useCodeBouton } from '../../../common/providers/code-bouton-provider';
import { routeAppointment, routeUserAccount } from '../../../routes';
import {useAuthStore} from '../auth-store';
import { useGiftCardStore } from '../../../gift-card/gift-card-store';
import { GiftCard } from '../../../gift-card/gift-card.types';
import { useIntl } from 'react-intl';
import { useTimezone } from '../../../common/providers/timezone-provider';
import {usePaymentStore} from "../../../subscription/payment-store";

export const AuthCallback: FC = () => {
  const codeBouton = useCodeBouton();
  const {authenticateCustomer} = useAuthStore();
  const {updateBooking} = useBookingStore();
  const {selectGift} = useGiftCardStore();
  const {selectSubscription, setSelectedPlanId} = usePaymentStore();
  const navigate = useNavigate();
  const {updateTimezone} = useTimezone();
  const intl = useIntl();

  const queryParams   = new URLSearchParams(window.location.search)
  const success       = queryParams.get('success')
  const accessToken   = queryParams.get('access_token')
  const refreshToken  = queryParams.get('refresh_token')
  const timezone      = queryParams.get('timezone')
  const error         = queryParams.get('error')

  const storedRedirectUri = sessionStorage.getItem(`redirectUri_${codeBouton}`);
  
  useEffect(() => {
    (async () => {
      // Récupérer également les informations de la réservation en cours
      const storedBooking = sessionStorage.getItem(`storedBooking_${codeBouton}`);
      if (storedBooking) {
          const reservationData = JSON.parse(storedBooking);
          updateBooking(reservationData);
          sessionStorage.removeItem(`storedBooking_${codeBouton}`);
      }
      // Récupérer également les informations du giftcard en cours
      const storedGiftcard = sessionStorage.getItem(`storedGiftcard_${codeBouton}`);
      if (storedGiftcard) {
        const parsedGiftCard = JSON.parse(storedGiftcard) as GiftCard;
        const service = parsedGiftCard.service;
        try {
          await selectGift(service);
        } catch (err) {
          console.error(err);
        }
        sessionStorage.removeItem(`storedGiftcard_${codeBouton}`);
      }
      // Récupérer également les informations de l'abonnement en cours
      const storedSubscription = sessionStorage.getItem(`storedSubscription_${codeBouton}`);
      if (storedSubscription) {
        const parsedSubscription = JSON.parse(storedSubscription);
        selectSubscription(parsedSubscription);
        sessionStorage.removeItem(`storedSubscription_${codeBouton}`);
      }
      const storedPlanId = sessionStorage.getItem(`storedSubscriptionPlan_${codeBouton}`);
      if (storedPlanId) {
        const parsedSubscriptionPlan = JSON.parse(storedPlanId);
        setSelectedPlanId(parsedSubscriptionPlan);
        sessionStorage.removeItem(`storedSubscriptionPlan_${codeBouton}`);
      }
      sessionStorage.removeItem(`redirectUri_${codeBouton}`);
      
      if((success && accessToken && refreshToken) || (accessToken && refreshToken)){
        // Récupérer la timezone si fourni
        if(timezone)
          updateTimezone(timezone);

        // Récupérer les informations de l'utilisateur à partir du token
        authenticateCustomer(accessToken, refreshToken)
        
        // Modifier l'URL en enlevant les paramètres en queryString
        window.history.replaceState({}, document.title, window.location.pathname);

        if(storedRedirectUri)
          navigate(storedRedirectUri)
        else
          navigate(generatePath(routeAppointment,{codeBouton, locale: intl.locale}));
      }
      else if(success && error){
        if(storedRedirectUri){
          const params = new URLSearchParams({
            success: success,
            error: error,
          });
          navigate(`${storedRedirectUri}?${params.toString()}`)
        }
      }
      else{
        navigate(generatePath(routeUserAccount))
      }
    })();
  }, );

  return null;
};
