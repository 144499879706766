import {FC, RefObject, useEffect, useRef, useState} from "react";
import styled, {useTheme} from "styled-components";
import {Button, Col, Dialog, Row, SpanSubtitle1, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {Tag} from "../../common/components/tag";
import { minimumFractionDigits } from "../../common/misc-util";

const Wrapper = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${({theme}) => theme.colors.grey95};
  overflow: hidden;
`;
const Title = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: ${({theme}) => theme.colors.primary};
  font-size: 16px;
  line-height: 22px;
  margin: 3px 0;
`;
const ServicesList = styled.div<{ show: boolean }>`
  ${({show}) => !show && `display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: clip;
  overflow: hidden`};
`;
const ButtonLink = styled.button<{ color?: string, small?: boolean }>`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: inherit;
  font-family: 'Open Sans', sans-serif;
  text-decoration: underline;
  font-weight: ${({small}) => small ? 400 : 600};
  font-size: 14px;
  line-height: 21px;
  color: ${({theme, color}) => color ? color : theme.colors.grey60};
`;
const Price = styled.p`
  font-family: "Open Sans", sans-serif;
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  color: ${({theme}) => theme.colors.grey40};
`;
const Caption = styled.p`
  font-family: "Open Sans", sans-serif;
  margin: 0;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  color: ${({theme}) => theme.colors.grey40};
`;
const ModalTitle = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: ${({theme}) => theme.colors.primary};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0;
`;

interface SubscriptionCardProps {
    services: (string | undefined)[];
    title: string;
    price: number;
    currency: string;
    subscriptionFees?: number | null;
    subscriptionConditions: string;
    unsubscriptionConditions: string;
    otherSubscriptionConditions: string;
    onSelect: () => void;
}

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
                                                                services,
                                                                title,
                                                                price,
                                                                currency,
                                                                subscriptionFees,
                                                                subscriptionConditions,
                                                                unsubscriptionConditions,
                                                                otherSubscriptionConditions,
                                                                onSelect
                                                            }) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const contentRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement | null>(null);
    const servicesRef = useRef<HTMLParagraphElement | null>(null);
    const [isOverFlowing, setIsOverFlowing] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const formatter = new Intl.NumberFormat(intl.locale, {style: 'currency', currency, minimumFractionDigits: minimumFractionDigits(price)});
    const parts = formatter.formatToParts(price);

    useEffect(() => {
        if (!servicesRef.current) {
            return
        }
        if (servicesRef.current?.offsetHeight < servicesRef.current?.scrollHeight) {
            setIsOverFlowing(true);
        }
    }, []);

    return <Wrapper ref={contentRef}>
        <Row gapColumn={[8]} alignItems={'center'}>
            <Col columns={[12, 12, 6, 6]}>
                <Title>{title}</Title>
                <SpanSubtitle1 style={{color: theme.colors.grey60}}>
                    <FormattedMessage
                        id={'paramsSubscriptionCardDescription'}
                        defaultMessage={'Cet abonnement donne accès aux prestations ci-dessous.'}
                    />
                </SpanSubtitle1>
                <Row wraps={'nowrap'} justifyContent={'flex-start'}>
                    <Col columns={[10]}>
                        <ServicesList ref={servicesRef} show={show} style={{margin: '10px 0'}}>
                            {services.map((el, index) => {
                                if (el === undefined) {
                                    return null
                                } else {
                                    return <Tag key={index} color={theme.colors.primary}>
                                        {el}
                                    </Tag>
                                }
                            })}
                        </ServicesList>
                    </Col>
                    <Col columns={[2]} style={{padding: 0, flexShrink: 0, margin: 'auto 15px 15px 0'}}>
                        {isOverFlowing && <ButtonLink onClick={() => setShow(!show)}>
                            <FormattedMessage id={'paramsSubscriptionCardOverFlowingButtonLabel'}
                                              defaultMessage={'Voir plus'}/>
                        </ButtonLink>}
                    </Col>
                </Row>
                <ButtonLink color={theme.colors.primary} small onClick={() => setOpenDialog(true)}>
                    <FormattedMessage
                        id={"paramsSubscriptionCardButtonLinkLabel"}
                        defaultMessage={"Toutes les conditions"}/>
                </ButtonLink>
            </Col>
            <Col columns={[12, 12, 5, 5]} style={{marginLeft: 'auto'}}>
                <Row wraps={'nowrap'} alignItems={['center']}>
                    <Col columns={[12, 12, 6]}>
                        <Price>
                            <FormattedMessage
                                id={'paramsSubscriptionCardPriceLabel'}
                                defaultMessage={'à partir de{br}{price}{br}/mois'}
                                values={{
                                    br: <br/>,
                                    price: <span style={{fontSize: '50px', margin: 0, lineHeight: '60px'}}>
                                        {parts.map((part, index) => {
                                            if (part.type === 'fraction' || part.type === 'decimal') {
                                                return <span key={index} style={{fontSize: '11px'}}>{part.value}</span>
                                            }
                                            return  <span key={index}>{part.value}</span>
                                        })}
                                    </span>
                                }}
                            />
                        </Price>
                        {subscriptionFees && <Caption>
                            <FormattedMessage
                                id={"paramsSubscriptionCardSubscriptionFeesCaption"}
                                defaultMessage={"{price} de frais d’inscription"}
                                values={{
                                    price: <FormattedNumber
                                        value={subscriptionFees}
                                        currency={currency}
                                        style={'currency'}
                                        minimumFractionDigits={minimumFractionDigits(subscriptionFees)}
                                    />
                                }}
                            />
                        </Caption>}
                    </Col>
                    <Col columns={[12, 12, 6]} style={{marginLeft: 'auto'}}>
                        <Button colorType="primary" onClick={onSelect}>
                            <FormattedMessage
                                id={'paramsSubscriptionCardSubscribeButtonLabel'}
                                defaultMessage={'Je m’abonne'}
                            />
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Dialog
            onClose={() => setOpenDialog(false)}
            show={openDialog}
            title={''}
            size={'lg'}>
            <div style={{maxHeight: '500px', overflow: 'auto'}}>
                <ModalTitle>
                    <FormattedMessage
                        id={'paramsSubscriptionCardModalTitle'}
                        defaultMessage={'Conditions: {name}'}
                        values={{name: title}}/>
                </ModalTitle>
                <Title style={{margin: '14px 0'}}>
                    <FormattedMessage
                        id={'paramsSubscriptionCardModalTitleSubscriptionConditions'}
                        defaultMessage={'Conditions d\'inscription:'}
                    />
                </Title>
                <SpanSubtitle1>{subscriptionConditions}</SpanSubtitle1>
                <Title style={{margin: '14px 0'}}>
                    <FormattedMessage
                        id={'paramsSubscriptionCardModalTitleUnsubscriptionConditions'}
                        defaultMessage={'Conditions désinscription:'}
                    />
                </Title>
                <SpanSubtitle1>{unsubscriptionConditions}</SpanSubtitle1>
                <Title style={{margin: '14px 0'}}>
                    <FormattedMessage
                        id={'paramsSubscriptionCardModalTitleOtherSubscriptionConditions'}
                        defaultMessage={'Autres conditions:'}
                    />
                </Title>
                <SpanSubtitle1>{otherSubscriptionConditions}</SpanSubtitle1>
            </div>
        </Dialog>
    </Wrapper>
}