import {createContext, FC, useCallback, useContext, useEffect, useState} from 'react';
import {CategoriesService, Service} from './service.types';
import {buildCategory} from './category-util';
import {getServiceList} from './service-api';
import {StoreProps} from '../common/commons.types';
import {PageTemplate} from '../common/components/page-template';
import {Configuration} from '../configuration/configuration.types';
import {DisabledContent} from '../common/components/disabled-content';
import {useIntl} from 'react-intl';

export interface ServicesContextProps {
    categories: CategoriesService | null;
    fetchData: () => Promise<void>;
    services: Service[]
}

const ServicesContext = createContext<ServicesContextProps>({
    categories: null,
    fetchData: () => Promise.reject(new Error('Not initialized')),
    services: []
});

const fetchAllServices = async (codeBouton: string, offset: number, giftable?: boolean, token?: string): Promise<Service[]> => {
    const resp = await getServiceList(codeBouton, offset, 20, token, giftable);
    if ((offset + resp.count) < resp.total) {
        return [...resp.result, ...await fetchAllServices(codeBouton, offset + resp.count, giftable, token)];
    }
    return resp.result;
}

export const useServicesStore = () => useContext(ServicesContext);

interface ServicesStoreProps extends StoreProps {
    giftable?: boolean;
    config: Configuration;
    showSubscriptions: boolean
}

export const ServicesStore: FC<ServicesStoreProps> = ({
                                                          children,
                                                          codeBouton,
                                                          token,
                                                          giftable,
                                                          config,
                                                          showSubscriptions
                                                      }) => {
    const intl = useIntl();
    const [services, setServices] = useState<Service[]>([]);
    const [categories, setCategories] = useState<CategoriesService | null>(null);
    const [servicesError, setServicesError] = useState<boolean>(false);

    useEffect(() => {
        setCategories(services.length > 0 ? buildCategory(services.map(el => {
            return {...el, downPayment: el.downPayment ?? config.paymentPercent / 100}
        })) : null)
        return () => setCategories(null)
    }, [config.paymentPercent, services]);

    const fetchData = useCallback(async () => {
        try {
            const result = await fetchAllServices(codeBouton, 0, giftable, token)
            if (result.length > 0) {
                setServicesError(false);
                setServices(result.map(service => {
                    return {...service, downPayment: service.downPayment ?? config.paymentPercent / 100}
                }));
            } else {
                setServicesError(true);
            }
        } catch (e) {
            console.error(e)
            setServicesError(true);
        }

    }, [codeBouton, giftable, token, config.paymentPercent]);

    if (!servicesError) {
        return <ServicesContext.Provider value={{categories, fetchData, services}}>
            {children}
        </ServicesContext.Provider>
    } else {
        return <PageTemplate configuration={config} codeBouton={codeBouton} showSubscriptions={showSubscriptions}>
            <DisabledContent message={intl.formatMessage({
                id: 'servicesErrorMessage',
                defaultMessage: 'Nos prestations ne sont actuellement pas disponibles en ligne.'
            })} phone={config.phone}/>
        </PageTemplate>
    }
}
