import {Button, Col, Row, SpanBody1, SpanBody2, useToaster} from "@linkeo.com/ui-lib-react";
import React, {FC, useEffect, useState} from "react";
import {statusToValid, statusValid, statusWaiting} from "../account.types";
import {CardLoading} from "../../../common/components/card-loading";
import {Separation} from "../../../common/components/separation";
import {useAccountStore} from "../account-store";
import {AccountCard, AccountPageWrapper} from "../components/common";
import {FormattedMessage, useIntl} from 'react-intl';
import {useConfigurationStore} from "../../../configuration/configuration-store";
import {PageTemplate} from "../../../common/components/page-template";
import {useAuthStore} from "../../authentification/auth-store";
import {useBookingStore} from "../../../booking/booking-store";
import {useConfirm} from "../../../common/providers/confirm-provider";
import {generatePath} from "react-router";
import {routeUserAccount} from "../../../routes";
import {useCodeBouton} from "../../../common/providers/code-bouton-provider";
import {usePaymentStore} from "../../../subscription/payment-store";
import {useTimezone} from "../../../common/providers/timezone-provider";

interface AppointmentCardProps {
    titlePrestation: string,
    datePrestation: string,
    timezone: string | null,
    clickCancelAppointment?: () => void
}

const AppointmentCard: FC<AppointmentCardProps> = ({
                                                       titlePrestation,
                                                       datePrestation,
                                                       timezone,
                                                       clickCancelAppointment
                                                   }) => {
    const intl = useIntl();

    return <>
        <Row justifyContent={['space-between']}>
            <Col>
                <div><SpanBody1>{titlePrestation}</SpanBody1></div>
                <SpanBody2><FormattedMessage id="dateTimeFormat" defaultMessage='Le {date} à {time} ' values={{
                    date: intl.formatDate(datePrestation, {
                        dateStyle: "full",
                        timeZone: timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
                    }),
                    time: intl.formatTime(datePrestation, {
                        timeStyle: 'short',
                        timeZone: timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
                    })
                }}/>
                </SpanBody2>
            </Col>
            <Col>
                {clickCancelAppointment ? (
                    <Button small colorType={"outlineSecondary"} icon={'close-outline'}
                            onClick={clickCancelAppointment}>
                        <FormattedMessage id={"AccountPageAppointmentsCancelButton"} defaultMessage={'Annuler'}/>
                    </Button>
                ) : null}
            </Col>
        </Row>
    </>
}

export const AppointmentsListingPage: FC = () => {
    const {loadAppointments, appointments} = useAccountStore()
    const {showSubscriptions} = usePaymentStore();
    const config = useConfigurationStore();
    const {timezone} = useTimezone();
    const {logOut} = useAuthStore();
    const {cancelAppointment} = useBookingStore();
    const intl = useIntl();
    const toast = useToaster();
    const {openConfirm} = useConfirm();
    const [loading, setLoading] = useState<boolean>(true);
    const codeBouton = useCodeBouton();

    useEffect(() => {
        loadAppointments().finally(() => setLoading(false));
    }, [loadAppointments])

    const onLogoutClick = async () => {
        await openConfirm({
            content: intl.formatMessage({
                id: 'accountPageLogoutConfirmation',
                defaultMessage: "Êtes-vous sûr(e) ?"
            }), title: intl.formatMessage({id: 'accountPageLogoutText', defaultMessage: "Déconnexion"})
        });
        logOut();
    }

    const clickCancelAppointment = async (appointmentId: string) => {
        await openConfirm({
            content: intl.formatMessage({
                id: 'AccountPageCancelAppointmentConfirmation',
                defaultMessage: "Êtes-vous sûr(e) de vouloir annuler ce rendez-vous ?"
            }),
            title: intl.formatMessage({id: 'AccountPageModalCancelConfirmationTitle', defaultMessage: "Annuler le RDV"})
        });
        setLoading(true);
        try {
            await cancelAppointment(appointmentId)
            await loadAppointments()
        } catch (e) {
            console.error(e)
            toast(intl.formatMessage({id: 'toastMessageError', defaultMessage: 'Une erreur est survenue'}))
        } finally {
            setLoading(false)
        }
    }

    return <PageTemplate configuration={config} codeBouton={codeBouton} showSubscriptions={showSubscriptions}>
        <AccountPageWrapper
            backLinkUrl={generatePath(routeUserAccount,{codeBouton,locale:intl.locale})}
            onLogoutClick={onLogoutClick}
            pageTitle={intl.formatMessage({
            id: 'AccountPageAppointmentsTitle',
            defaultMessage: 'Vos rendez-vous'
        })}>
            {loading ? <CardLoading/> :
                <>
                    <AccountCard icon={"question-mark-outline"}
                                 titleName={intl.formatMessage({
                                         id: 'AccountPageAppointmentsAwaitingApprovalTitle',
                                         defaultMessage: '{number, plural, =1 {rendez-vous} other {rendez-vous}} en attente de validation'
                                     },
                                     {number: appointments.filter(a => a.status === statusToValid).length})}>
                        {appointments.filter(a => a.status === statusToValid).map(e => {
                            return <React.Fragment key={e.id}>
                                <Separation/>
                                <AppointmentCard
                                    titlePrestation={e.service.title}
                                    datePrestation={e.date}
                                    timezone={timezone}
                                    clickCancelAppointment={() => clickCancelAppointment(e.id)}/>
                            </React.Fragment>
                        })}
                    </AccountCard>

                    <AccountCard icon={"bookmark-outline"}
                                 titleName={intl.formatMessage({
                                         id: 'AccountPageUpcomingAppointmentsTitle',
                                         defaultMessage: '{number, plural, =1 {rendez-vous} other {rendez-vous}} à venir'
                                     },
                                     {number: appointments.filter(a => a.status === statusValid && new Date(a.date) >= new Date()).length})}>
                        {appointments.filter(a => a.status === statusValid && new Date(a.date) >= new Date()).map(e => {
                            return <React.Fragment key={e.id}>
                                <Separation/>
                                <AppointmentCard
                                    titlePrestation={e.service.title}
                                    datePrestation={e.date}
                                    timezone={timezone}
                                    clickCancelAppointment={() => clickCancelAppointment(e.id)}/>
                            </React.Fragment>
                        })}
                    </AccountCard>

                    <AccountCard icon={"wait-outline"}
                                 titleName={intl.formatMessage({
                                         id: 'AccountPageAppointmentsWaitingListTitle',
                                         defaultMessage: '{number, plural, =1 {rendez-vous} other {rendez-vous}} sur liste d’attente'
                                     },
                                     {number: appointments.filter(a => a.status === statusWaiting).length})}>
                        {appointments.filter(a => a.status === statusWaiting).map(e => {
                            return <React.Fragment key={e.id}>
                                <Separation/>
                                <AppointmentCard
                                    titlePrestation={e.service.title}
                                    datePrestation={e.date}
                                    timezone={timezone}/>
                            </React.Fragment>
                        })}
                    </AccountCard>

                    <AccountCard icon={"menu-2-fill"}
                                 titleName={intl.formatMessage({
                                     id: 'AccountPageAppointmentsHistoryTitle',
                                     defaultMessage: 'Historique des rendez-vous '
                                 })}>
                        {appointments.filter(a => a.status === statusValid && new Date(a.date) < new Date()).map(e => {
                            return <React.Fragment key={e.id}>
                                <Separation/>
                                <AppointmentCard
                                    titlePrestation={e.service.title}
                                    datePrestation={e.date}
                                    timezone={timezone}/>
                            </React.Fragment>
                        })}
                    </AccountCard>
                </>}
        </AccountPageWrapper>
    </PageTemplate>
}
