import {
    createContext,
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {PaymentIntent, Subscription, SubscribePayload, SubscriberSubscription} from './payment-types';
import {PaymentApi} from "./payment-api";
import {paymentApi} from "../common/config/uri-api";
import {Loader} from "../common/components/loader";

interface PaymentContextProps {
    subscriptionsList: Subscription[];

    fetchSubscriptionList(): Promise<void>;

    showSubscriptions: boolean;

    selectedSubscription: Subscription | null;

    selectSubscription: (subscription: Subscription) => void;

    createSubscription: (id: string, body: SubscribePayload) => Promise<PaymentIntent>;

    getSubscription: (id: string) => Promise<SubscriberSubscription>

    cancelSubscription(id: string): Promise<void>;

    selectedPlanId: string;

    setSelectedPlanId: Dispatch<SetStateAction<string>>
}

const PaymentContext = createContext<PaymentContextProps>({
    subscriptionsList: [],
    fetchSubscriptionList: () => Promise.reject(new Error('Provider not initialized')),
    showSubscriptions: false,
    selectedSubscription: null,
    selectSubscription: () => {
    },
    createSubscription: () => Promise.reject(new Error('Provider not initialized')),
    getSubscription: () => Promise.reject(new Error('Provider not initialized')),
    cancelSubscription: () => Promise.reject(new Error('Provider not initialized')),
    selectedPlanId:'',
    setSelectedPlanId: () => {}
})

export const usePaymentStore = () => useContext<PaymentContextProps>(PaymentContext);

interface PaymentStoreProps {
    codeBouton: string;
    token?: string
}

export const PaymentStore: FC<PaymentStoreProps> = ({codeBouton, token, children}) => {
    const api = useMemo(() => new PaymentApi(paymentApi), [])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [subscriptionsList, setSubscriptionsList] = useState<Subscription[]>([]);
    const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null)
    const [showSubscriptions, setShowSubscriptions] = useState<boolean>(false);
    const [selectedPlanId, setSelectedPlanId] = useState<string>('');

    const fetchSubscriptionList = useCallback(async () => {
        let total;
        const limit = 20;
        let subscriptions: Subscription[] = [];
        do {
            const response = await api.getSubscriptionList(codeBouton, limit, subscriptions.length, token);
            if (total === undefined) {
                total = response.total
            }
            subscriptions = [...subscriptions, ...response.result]
        } while (subscriptions.length < total)
        setSubscriptionsList(subscriptions);
        setShowSubscriptions(subscriptions.length > 0)
    }, [api, codeBouton, token])

    useEffect(() => {
        setIsLoading(true);
        fetchSubscriptionList()
            .catch(e => console.error(e))
            .finally(() => setIsLoading(false))
    }, [fetchSubscriptionList])

    const selectSubscription = useCallback((subscription: Subscription) => {
        setSelectedSubscription({...subscription, plans: subscription.plans.filter(plan => plan.active)})
    }, [])

    const createSubscription = async (id: string, body: SubscribePayload) => {
        return await api.createSubscription(codeBouton, id, body, token);
    }

    const getSubscription = useCallback(async (id: string) => {
        return await api.getSubscription(codeBouton, id, token)
    }, [api, codeBouton, token])

    const cancelSubscription = async (id: string) => {
        await api.deleteSubscriberSubscription(codeBouton, id, token)
    }

    return <PaymentContext.Provider
        value={{
            subscriptionsList,
            fetchSubscriptionList,
            showSubscriptions,
            selectedSubscription,
            selectSubscription,
            createSubscription,
            getSubscription,
            cancelSubscription,
            setSelectedPlanId,
            selectedPlanId
        }}>
        <Loader loading={isLoading}>{children}</Loader>
    </PaymentContext.Provider>
}