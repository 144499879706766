import {FC, useState} from "react";
import {Select, SelectOption, SpanSubtitle1} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";
import { useTimezone } from "../providers/timezone-provider";

interface ParamsTimezoneFormProps {
    timezoneOptions: SelectOption<string>[]
    handleTimezone: (timezone: string) => void
}

export const ParamsTimezoneForm: FC<ParamsTimezoneFormProps> = ({timezoneOptions, handleTimezone}) => {
    const {timezone} = useTimezone();
    const [timezoneSelected, setTimezoneSelected] = useState<SelectOption<string>>(timezoneOptions.find(option => option.value === timezone) || timezoneOptions[0]);

    return <div style={{position: 'relative', zIndex: 11}}>
        <form>
            <SpanSubtitle1>
                <FormattedMessage
                    id={'bookingDateSelectionTimezoneSubtitle'}
                    defaultMessage={'Fuseau horaire'}
                />
            </SpanSubtitle1>
            <Select
                selected={timezoneSelected}
                onSelect={(tz) => {
                    setTimezoneSelected(tz);
                    handleTimezone(tz.value);
                }}
                options={timezoneOptions}
            />
        </form>
    </div>
    
}