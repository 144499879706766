import React, {FC, FormEvent} from "react";
import {Card, Col, Flex, Radio, Row, SpanBody2, SpanSubtitle1} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {SubscribePayload, SubscriptionPlan} from "../payment-types";
import {CircledSvg} from "../../common/components/circled-svg";
import {InfoPrice} from "../../common/components/info-price";
import styled, {useTheme} from "styled-components";
import {lighten} from "polished";
import {minimumFractionDigits} from "../../common/misc-util";

export const subscribeId = 'subscribeId';

const SaveCell = styled.div<{ $min: boolean, disabled: boolean }>`
  width: 100%;
  border: 1px solid ${({theme, $min}) => $min ? theme.colors.success : theme.colors.danger};
  background-color: ${({
                         theme,
                         $min
                       }) => $min ? lighten(0.45, theme.colors.success) : lighten(0.26, theme.colors.danger)};
  padding: 0 6px;
  border-radius: 3px;
  text-align: center;
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
`

interface SubscriptionEntrySectionProps {
    plans: SubscriptionPlan[];
    currency: string;
    name: string;
    subscriptionFees?: number | null;
    form: SubscribePayload;
    onPlanChange: (id: string) => void;
    getFrequencyTitle: (number: number) => string;
    onSubscribeSubmit: () => void;
    readOnly: boolean;
    planIdIsSelected: boolean;
}

export const SubscriptionEntrySection: FC<SubscriptionEntrySectionProps> = ({
                                                                                plans,
                                                                                currency,
                                                                                name,
                                                                                subscriptionFees,
                                                                                form,
                                                                                onPlanChange,
                                                                                getFrequencyTitle,
                                                                                onSubscribeSubmit,
                                                                                readOnly,
                                                                                planIdIsSelected
                                                                            }) => {
    const intl = useIntl();
    const theme = useTheme();

    const yearlyMax = Math.max(...plans.map(plan => (plan.price! / plan.numberOfMonths) * 12));
    const yearlyMin = Math.min(...plans.map(plan => (plan.price! / plan.numberOfMonths) * 12));

    const getFrequencySubtitle = (number: number): string => {
        switch (number) {
            case 12:
                return intl.formatMessage({
                    id: 'subscriptionFrequencySubTitleYearly',
                    defaultMessage: 'Paiement en une fois'
                })
            case 3:
                return intl.formatMessage({
                    id: 'subscriptionFrequencySubTitleQuaterly',
                    defaultMessage: 'Prélèvement 1 fois tous les 3 mois'
                })
            case 1:
            default:
                return intl.formatMessage({
                    id: 'subscriptionFrequencySubTitleMonthly',
                    defaultMessage: 'Prélèvement 1 fois par mois'
                })
        }
    }

    const getFrequency = (number: number): string => {
        switch (number) {
            case 12:
                return intl.formatMessage({id: 'subscriptionFrequencyTitleYear', defaultMessage: 'Annuel'})
            case 3:
                return intl.formatMessage({id: 'subscriptionFrequencyTitleQuaterly', defaultMessage: 'Trimestriel'})
            case 1:
            default:
                return intl.formatMessage({id: 'subscriptionFrequencyTitleMonthly', defaultMessage: 'Mensuel'})
        }
    }

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onSubscribeSubmit()
    }

    return <form id={subscribeId} onSubmit={_onSubmit}>
        {!planIdIsSelected && <Card style={{margin: '24px 0'}}>
            <SpanBody2>
                <FormattedMessage id={'subscriptionConfirmationPageSubtitle1'}
                                  defaultMessage={'Quel type de paiement souhaitez-vous ?'}/>
            </SpanBody2>
            <Row gapColumn={[24]} style={{marginTop: '24px'}}>
                {plans.map(plan => <Col columns={[12, 12, 3.75]} key={plan.id}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between'
                                        }}>
                    <Radio name={'subscriptionPlanRadioOption'} onChange={() => onPlanChange(plan.id)}
                           value={plan.id === form.planId} disabled={readOnly}>
                        <strong>{getFrequencyTitle(plan.numberOfMonths)} ({intl.formatNumber(plan.price || 0, {
                            style: 'currency',
                            currency,
                            minimumFractionDigits: minimumFractionDigits(plan.price!)
                        })})</strong>
                        <br/>{getFrequencySubtitle(plan.numberOfMonths)}
                    </Radio>
                    {plans.length > 0 && yearlyMax !== yearlyMin &&
                        <SaveCell $min={(((plan.price! / plan.numberOfMonths) * 12) === yearlyMin)} disabled={readOnly}>
                            <SpanSubtitle1
                                style={{color: ((plan.price! / plan.numberOfMonths) * 12) === yearlyMax ? theme.colors.danger : theme.colors.grey30}}>
                                {((plan.price! / plan.numberOfMonths) * 12) === yearlyMin
                                    ? <strong>
                                        <FormattedMessage id={'subscriptionEntrySectionSaveYearlyMin'}
                                                          defaultMessage={'Economie de {value} {br}sur 12 mois'}
                                                          values={{
                                                              value: intl.formatNumber((yearlyMax - yearlyMin), {
                                                                  style: 'currency',
                                                                  currency,
                                                                  minimumFractionDigits: minimumFractionDigits(yearlyMax - yearlyMin)
                                                              })
                                                          }}/>
                                    </strong>
                                    : ((plan.price! / plan.numberOfMonths) * 12) === yearlyMax
                                        ? `+ ${intl.formatNumber((yearlyMax - yearlyMin), {
                                            style: 'currency',
                                            currency,
                                            minimumFractionDigits: minimumFractionDigits(yearlyMax - yearlyMin)
                                        })}`
                                        : <FormattedMessage id={'subscriptionEntrySectionSaveYearlyMin'}
                                                            defaultMessage={'Economie de {value} {br}sur 12 mois'}
                                                            values={{
                                                                value: intl.formatNumber(yearlyMax - ((plan.price! / plan.numberOfMonths) * 12), {
                                                                    style: 'currency',
                                                                    currency,
                                                                    minimumFractionDigits: minimumFractionDigits(yearlyMax - ((plan.price! / plan.numberOfMonths) * 12))
                                                                })
                                                            }}/>
                                }
                            </SpanSubtitle1>
                        </SaveCell>}
                </Col>)}
            </Row>
        </Card>}
        <Card style={{marginBottom: '32px'}}>
            <Row alignItems={'center'}>
                <Col columns={[1]}>
                    <CircledSvg icon={'calendar-outline'}/>
                </Col>
                <Col columns={[11]}>
                    <Row justifyContent={['space-between']}>
                        <Col>
                            <div><SpanBody2>1 {name} {getFrequency(plans.find(el => el.id === form.planId)?.numberOfMonths || 0)}</SpanBody2></div>
                            {subscriptionFees && <SpanSubtitle1>
                                <FormattedMessage id={'subscriptionEntrySectionSubscriptionFeesTitle'}
                                                  defaultMessage={'Frais d’inscription'}/>
                            </SpanSubtitle1>}
                        </Col>
                        <Col>
                            <Flex style={{columnGap: '6px', justifyContent: 'flex-end'}}>
                                <InfoPrice currency={currency}/>
                                <SpanBody2>
                                    <FormattedNumber
                                        value={plans.find(el => el.id === form.planId)?.price || 0}
                                        style={'currency'}
                                        currency={currency}
                                        minimumFractionDigits={minimumFractionDigits(plans.find(el => el.id === form.planId)?.price || 0)}
                                    />
                                </SpanBody2>
                            </Flex>
                            {subscriptionFees && <Flex style={{columnGap: '6px', justifyContent: 'flex-end'}}>
                                <InfoPrice currency={currency}/>
                                <SpanSubtitle1>
                                    <FormattedNumber
                                        value={subscriptionFees} style={'currency'} currency={currency}
                                        minimumFractionDigits={minimumFractionDigits(subscriptionFees)}/>
                                </SpanSubtitle1></Flex>}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    </form>
}