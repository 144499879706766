import {SpanBody2, SpanSubtitle1, useToaster} from "@linkeo.com/ui-lib-react"
import {FC, ReactNode, useEffect} from "react"
import {CardLoading} from "../../../common/components/card-loading"
import {useConfigurationStore} from "../../../configuration/configuration-store";
import {AccountEditForm, AccountFormProps} from "../components/account-edit-form";
import {FormattedMessage, useIntl} from "react-intl";
import {Link as RouterLink} from "react-router-dom";
import styled from "styled-components";
import {useAccountStore} from "../account-store";
import {AccountCard, AccountPageWrapper} from "../components/common";
import {useConfirm} from "../../../common/providers/confirm-provider";
import {useAuthStore} from "../../authentification/auth-store";
import {CustomerAuth} from "../account.types";
import {
    routeUserAccount,
    routeUserAccountAppointmentsList,
    routeUserAccountGiftCardsList,
    routeUserAccountSubscriptionsList
} from "../../../routes";
import {generatePath} from "react-router";
import {useCodeBouton} from "../../../common/providers/code-bouton-provider";
import { useServicesStore } from "../../../service/services-store";

const Link = styled(RouterLink)`
  &:active, &:hover, &:visited {
    color: initial;
  }
`

const DetailsLink: FC<{ children: ReactNode, to: string }> = ({to, children}) => {
    return <Link to={to}><SpanBody2
        style={{textDecorationLine: 'underline', textDecoration: "none"}}
    >{children}</SpanBody2>
    </Link>;
}

export const AccountPage: FC = () => {

    const config = useConfigurationStore();
    const {
        giftCardsCount,
        appointmentsCount,
        subscriptionsCount,
        loadSubscriptions,
        loadAppointments,
        userInformations,
        updatePersonalInformations,
        loadGiftcards
    } = useAccountStore();
    const {fetchData, services} = useServicesStore();
    const {logOut} = useAuthStore();
    const codeBouton = useCodeBouton();
    const intl = useIntl();
    const {openConfirm} = useConfirm();
    const toast = useToaster();

    useEffect(() => {
        loadAppointments().catch(() => toast(intl.formatMessage({
            id: 'errorLoadingAppointments',
            defaultMessage: 'Erreur lors du chargement des rendez-vous'
        })));
    }, [loadAppointments, intl, toast])

    useEffect(() => {
        loadGiftcards().catch(() => toast(intl.formatMessage({
            id: 'toastMessageError',
            defaultMessage: 'Une erreur est survenue'
        })));
    }, [intl, toast, loadGiftcards])

    useEffect(() => {
        Promise.all([loadSubscriptions(), fetchData()]).catch(() => toast(intl.formatMessage({
            id: 'toastMessageError',
            defaultMessage: 'Une erreur est survenue'
        })))
    }, [intl, loadSubscriptions, toast, fetchData])

    const onLogoutClick = async () => {
        await openConfirm({
            content: intl.formatMessage({
                id: 'accountPageLogoutConfirmation',
                defaultMessage: "Êtes-vous sûr(e) ?"
            }), title: intl.formatMessage({id: 'accountPageLogoutText', defaultMessage: "Déconnexion"})
        });
        logOut();
    }

    const updateAccount = async (account: AccountFormProps) => {
        await updatePersonalInformations(account);
    }
    return <AccountPageWrapper
        backLinkUrl={generatePath(routeUserAccount, {codeBouton, locale: intl.locale})}
        onLogoutClick={onLogoutClick}
        pageTitle={intl.formatMessage({id: 'AccountPageTitle', defaultMessage: 'Mon compte'})}
        showBackLink={false}>
        <AccountEditForm account={userInformations as CustomerAuth} onUpdate={updateAccount} onCancel={() => {
        }}/>

        {appointmentsCount === undefined ? <CardLoading/> :
            <AccountCard icon={"book-outline"}
                         titleName={intl.formatMessage({
                             id: 'AccountPageBookingTitle',
                             defaultMessage: 'Réservations ({number})'
                         }, {number: appointmentsCount})}>
                <div>
                    <SpanSubtitle1>
                        <FormattedMessage id="AccountPageBookingSubtitle"
                                          defaultMessage='Suivez vos demandes en cours et consultez l’historique des rendez-vous que vous avez déjà effectués.'/>
                    </SpanSubtitle1>
                </div>
                <DetailsLink to={generatePath(routeUserAccountAppointmentsList, {codeBouton, locale: intl.locale})}>
                    <FormattedMessage id='AccountPageBookingButtonLabel'
                                      defaultMessage='Voir tous les rendez-vous'/>
                </DetailsLink>
            </AccountCard>
        }

        {giftCardsCount === undefined ? <CardLoading/> :
            <AccountCard icon={"gift-outline"}
                         titleName={intl.formatMessage({
                             id: 'AccountPageGiftcardsTitle',
                             defaultMessage: 'Bons Cadeaux ({number})'
                         }, {number: giftCardsCount})}>
                <div>
                    <SpanSubtitle1>
                        <FormattedMessage id="AccountPageGiftcardsSubtitle"
                                          defaultMessage='Retrouvez les bon cadeaux que vous avez acheté et suivez leur utilisation.'/>
                    </SpanSubtitle1>
                </div>
                <DetailsLink to={generatePath(routeUserAccountGiftCardsList, {codeBouton, locale: intl.locale})}>
                    <FormattedMessage id='AccountPageGiftcardsButtonLabel'
                                      defaultMessage='Voir tous les bons cadeaux'/>
                </DetailsLink>
            </AccountCard>}

        {subscriptionsCount === undefined || services.length === 0 ? <CardLoading/> :
            <AccountCard icon={"bookmark-outline"}
                         titleName={intl.formatMessage({
                             id: 'AccountPageSubscriptionTitle',
                             defaultMessage: 'Abonnements ({number})'
                         }, {number: subscriptionsCount})}>
                <div>
                    <SpanSubtitle1>
                        <FormattedMessage id="AccountPageSubscriptionsSubtitle"
                                          defaultMessage='Consultez les détails de vos abonnements.'/>
                    </SpanSubtitle1>
                </div>
                <DetailsLink to={generatePath(routeUserAccountSubscriptionsList, {codeBouton, locale: intl.locale})}>
                    <FormattedMessage id='AccountPageSubscriptionsButtonLabel'
                                      defaultMessage='Voir tous les abonnements'/>
                </DetailsLink>
            </AccountCard>}

        <AccountCard icon={"phone-outline"} titleName={config.phone}>
            <div style={{marginTop: '16px'}}><SpanSubtitle1>
                <FormattedMessage id="accountPageContactInfoLabel1"
                                  defaultMessage={'Un empêchement, un imprévu ? Une question sur nos services ? N’hesitez pas à nous contacter.'}/>
                <div><FormattedMessage id="accountPageContactInfoLabel2"
                                       defaultMessage={'N’hesitez pas à nous contacter.'}/></div>
            </SpanSubtitle1></div>
        </AccountCard>
    </AccountPageWrapper>
};
