import {
    routeSubscription,
    routeSubscriptionConfirmation, routeSubscriptionConfirmationProposal,
    routeSubscriptionSummary,
    routeSubscriptionValidation
} from "../../routes";
import {FC} from "react";
import {Routes, Route} from "react-router-dom";
import {AuthenticationPage} from "../../customer/authentification/pages/authentication-page";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {useAuthStore} from "../../customer/authentification/auth-store";
import {RegisterPhonePage} from "../../customer/authentification/pages/register-phone-page";
import {SubscriptionSelectionPage} from "./subscription-selection-page";
import {ServicesStore} from "../../service/services-store";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";
import {usePaymentStore} from "../payment-store";
import {SubscriptionConfirmationPage} from "./subscription-confirmation-page";
import {SubscriptionSummaryPage} from "./subscription-summary-page";
import {SubscriptionPaymentValidation} from "./subscription-payment-validation";

const getLocalPath = (fullPath: string): string => {
    const re = new RegExp(`^${routeSubscription}`);
    return fullPath.replace(re, '');
}

export const IndexPage: FC = () => {
    const config = useConfigurationStore();
    const {authData} = useAuthStore();
    const codeBouton = useCodeBouton();
    const {showSubscriptions} = usePaymentStore();

    if (authData && (!authData.phone || !authData.birthDate)) {
        return <RegisterPhonePage/>
    }

    if (!config.appointment.allowAnonymous && !authData) {
        return <AuthenticationPage/>
    }

    return <ServicesStore
        codeBouton={codeBouton} token={authData?.accessToken.token} config={config}
        showSubscriptions={showSubscriptions}>
        <Routes>
            <Route path={getLocalPath(routeSubscription)} element={<SubscriptionSelectionPage/>}/>
            <Route path={getLocalPath(routeSubscriptionConfirmation)} element={<SubscriptionConfirmationPage />}/>
            <Route path={getLocalPath(routeSubscriptionSummary)} element={<SubscriptionSummaryPage />} />
            <Route path={getLocalPath(routeSubscriptionValidation)} element={<SubscriptionPaymentValidation />} />
            <Route path={getLocalPath(routeSubscriptionConfirmationProposal)} element={<SubscriptionConfirmationPage />} />
        </Routes>
    </ServicesStore>
}