export const routeHome = '/:codeBouton/:locale';
export const routeBookingIndex = '/:codeBouton/:locale/appointment/*';
export const routeAppointment = '/:codeBouton/:locale/appointment';
export const routeDateSelection = '/:codeBouton/:locale/appointment/date-selection';
export const routeConfirmation = '/:codeBouton/:locale/appointment/confirmation';
export const routeDetails = '/:codeBouton/:locale/appointment/details';
export const routeBookingValidation = '/:codeBouton/:locale/appointment/validation/:bookingId';
export const routeSummary = '/:codeBouton/:locale/appointment/summary/:bookingId';
export const routeSubscription = '/:codeBouton/:locale/subscription';
export const routeSubscriptionIndex = '/:codeBouton/:locale/subscription/*';
export const routeSubscriptionConfirmation = '/:codeBouton/:locale/subscription/confirmation';
export const routeSubscriptionConfirmationProposal = '/:codeBouton/:locale/subscription/confirmation/:subscriptionId/:planId';
export const routeSubscriptionValidation = '/:codeBouton/:locale/subscription/validation/:id';
export const routeSubscriptionSummary = '/:codeBouton/:locale/subscription/summary/:id';
export const routeGiftCard = '/:codeBouton/:locale/giftcard';
export const routeGiftCardIndex = '/:codeBouton/:locale/giftcard/*';
export const routeGiftCardConfirmation = '/:codeBouton/:locale/giftcard/confirmation';
export const routeGiftCardPaymentValidation = '/:codeBouton/:locale/giftcard/validation/:giftCardId';
export const routeGiftCardSummary = '/:codeBouton/:locale/giftcard/summary/:giftCardId';
export const routeGiftCardDisplay = '/:codeBouton/:locale/giftcard/display/:giftCardId';
export const routeResetpassword = '/:codeBouton/:locale/resetpassword';
export const routeUserAccount = '/:codeBouton/:locale/account/*';
export const routeUserAccountAppointmentsList = '/:codeBouton/:locale/account/appointments';
export const routeError = '/500';
export const routeErrorNotFound = '/404';
export const routePageNotFound = '*';
export const routeUserAccountGiftCardsList = '/:codeBouton/:locale/account/gift-cards';
export const routeUserAccountSubscriptionsList = '/:codeBouton/:locale/account/subscriptions';
export const routeAuthCallback = '/:codeBouton/:locale/auth/callback';

export const routeGlobalLegalNotice='/:locale/legal-notice';
export const routeCustomerLegalNotice='/:codeBouton/:locale/legal-notice';
export const routeCustomerTermsOfSales='/:codeBouton/:locale/terms-of-sales';
