import {BookEntry, Booking, BookingReminder, Choice, Option} from './book.types';
import {BookingResponse} from './booking.api';
import {Option as APIOption} from './book.types';

export const InitBookingOptionsActionType = "initBookingOptions";

interface InitBookingOptionsAction {
    type: typeof InitBookingOptionsActionType;
    options: APIOption[];
}

const initBookingOptions = (booking: Booking, apiOptions: APIOption[]): Booking => {
    const options = apiOptions.map(option => {
        return {
            ...option, total: 0, choices: option.choices.map(choice => {
                return {...choice, quantity: 0}
            })
        }
    })
    return {...booking, optionsTotal: 0, options};
}


export const SelectServicesActionType = "selectServices";
interface SelectServicesAction {
    bookEntries: BookEntry[];
    type: typeof SelectServicesActionType;
}
const setServicesInBooking = (booking: Booking, appointments: BookEntry[]) => {
    return {...booking, appointments}
}


const setBookingOptions = (booking: Booking, options: Option[]): Booking => {
    const selected = booking.appointments.filter(f => f.selected);
    const servicesTotal = selected.reduce((price, appointment) => price + (appointment.service.price*appointment.spots), 0);
    const entriesOptionsTotal = selected.reduce((price, bookEntry) => price + getOptionsTotal(bookEntry.options)*bookEntry.spots, 0);
    const bookingOptionsTotal = getOptionsTotal(options);
    return {
        ...booking,
        options,
        optionsTotal: getOptionsTotal(options),
        date: undefined,
        total: servicesTotal + entriesOptionsTotal + bookingOptionsTotal
    }
}

const setAppointments = (booking: Booking, appointments: BookEntry[]): Booking => {
    const selectedBookEntries = appointments.filter(f => f.selected);
    const bookEntriesTotal = selectedBookEntries.reduce((price, appointment) => price + (appointment.service.price*appointment.spots), 0);
    const optionsTotal = (booking.optionsTotal || 0) + selectedBookEntries.reduce((price, bookEntry) => price + getOptionsTotal(bookEntry.options)*bookEntry.spots, 0);
    return {
        ...booking,
        appointments: appointments.map((ap) => {
            return {...ap, optionsTotal: getOptionsTotal(ap.options)*ap.spots}
        }),
        date: undefined,
        total: bookEntriesTotal + optionsTotal
    }
}

export const UpdateBookingActionType = "updateBooking";
export interface BookingUpdate {
    appointments?: BookEntry[];
    date?: string;
    notes?: string;
    reminder?: BookingReminder;
    options?: Option[];
    waitingList?: boolean;
}
interface UpdateBookingAction {
    type: typeof UpdateBookingActionType;
    update: BookingUpdate;
}


const updateBooking = (state: Booking, {appointments, date, notes, reminder, options, waitingList}: BookingUpdate): Booking => {
    if (appointments) {
        state = setAppointments(state, appointments);
    }
    if (date) {
        state = {...state, date}
    }
    if (notes) {
        state = {...state, notes}
    }
    if (reminder) {
        state = {...state, reminder}
    }
    if (options) {
        state = setBookingOptions(state, options);
    }
    if (waitingList) {
        state = {...state, waitingList}
    }
    return state;
}
export const UpdateFromAPIResponse = "updateFromAPIResponse";
interface UpdateFromAPIResponseAction {
    type: typeof UpdateFromAPIResponse;
    response: BookingResponse
}

const updateFromApiResponse = (booking: Booking, response: BookingResponse): Booking => {
    const respAppointments = [...response.appointments];
    const appointments = booking.appointments.map(bookEntry => {
        if (!bookEntry.selected) {
            return {...bookEntry}
        }
        if (bookEntry.subscription) {
            return  {...respAppointments.shift() as BookEntry, selected: true, subscription: true}
        }
        return {...respAppointments.shift() as BookEntry, selected: true}
    })
    return {...response, appointments}
}

export const ClearBookingActionType = 'clearBooking';

interface ClearBookingAction {
    type: typeof ClearBookingActionType;
}

export type BookingAction =
    UpdateBookingAction
    | SelectServicesAction
    | ClearBookingAction
    | UpdateFromAPIResponseAction
    | InitBookingOptionsAction

export const defaultBooking = {
    reminder: {email: true, sms: true},
    appointments: [],
    options: []
}

const getOptionsTotal = (options: Option[] | undefined) => {
    return options?.reduce((total, option) => {
        const totalChoicesPrice = option.choices.reduce((price: number, choice: Choice) => {
            return price + (choice.total ?? ((choice.quantity || 0) * (choice.price || 0)))
        }, 0);
        let extraCharge = 0;
        const totalQty = option.choices.reduce((quantity, choice) => quantity + (choice.quantity || 0), 0);
        const maxFree = option.maxFree || Number.POSITIVE_INFINITY
        if (totalQty > maxFree) {
            extraCharge = (totalQty - maxFree) * (option.extraCharge || 0);
        }
        return total + (extraCharge > 0 ? extraCharge : totalChoicesPrice);
    }, 0) || 0
}

export const BookingReducer = (state: Booking, action: BookingAction): Booking => {
    switch (action.type) {
        case UpdateFromAPIResponse:
            return updateFromApiResponse(state, action.response);
        case UpdateBookingActionType:
            return updateBooking(state, action.update);
        case SelectServicesActionType:
            return setServicesInBooking(state, action.bookEntries);
        case ClearBookingActionType:
            return {...defaultBooking};
        case InitBookingOptionsActionType :
            return initBookingOptions(state, action.options);
        default:
            throw new Error("unexpected action");
    }
}
