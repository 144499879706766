import React, {FC, FormEvent, useReducer, useState} from 'react';
import {Button, Flex, ReducerObject, ReducerObjectType, useToaster, DatePicker} from '@linkeo.com/ui-lib-react';
import {GetErrorsRegisterAdditionalInformationForm} from '../authentification.validator';
import {ErrorsBuilder} from '../../../common/errors-builder';
import {useAuthStore} from '../auth-store';
import { useIntl, FormattedMessage } from 'react-intl';
import {PhoneNumberInput} from '../../components/phonenumber/input';
import {useLocale} from "../../../common/providers/intl.provider";
import { format } from 'date-fns-tz';

interface CustomerRegisterAdditionalInformation {
  birthDate: string,
  phone: string
}

export const RegisterPhoneForm: FC = () => {
  const toast = useToaster();
  const intl = useIntl();
  const {locale} = useLocale();
  const {authData ,updateAdditionalInformation} = useAuthStore();
  const lang = locale.split('-')[1].toLowerCase();

  const [getLoading, setLoading] = useState<boolean>(false);
  const [getFormControl, setFormControl] = useReducer<ReducerObjectType<CustomerRegisterAdditionalInformation>>(ReducerObject, {
    birthDate: authData?.birthDate ? authData.birthDate : '',
    phone: authData?.phone ? authData.phone : ''
  });
  const [errors, setErrors] = useState<ErrorsBuilder<CustomerRegisterAdditionalInformation>>(new ErrorsBuilder<CustomerRegisterAdditionalInformation>());

  const emitSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (getLoading) {
      return;
    }
    const checkError = GetErrorsRegisterAdditionalInformationForm(getFormControl);
    setErrors(checkError);
    if (checkError.hasError()) {
      return
    }
    setLoading(true);

    updateAdditionalInformation(getFormControl.birthDate, getFormControl.phone)
      .then(() => {
          toast(intl.formatMessage({id: 'registerAdditionalInformationFormToastConfirm', defaultMessage: 'Information complémentaire enregistrée'}), {
            type: 'success',
            outlined: true,
            icon: "award-outline"
          })
    })
    .catch((err) => {
      if (err.response.status === 400) {
        toast(intl.formatMessage({id: 'telephoneError', defaultMessage: 'Le numéro de téléphone est invalide'}))
      }
    }).finally(() => setLoading(false))
  }
  return <form onSubmit={emitSubmit}>
    <DatePicker 
      locale={lang}
      label={intl.formatMessage({id: 'accountEditBirthDateLabel', defaultMessage: 'Date de naissance'})} required
      value={getFormControl.birthDate ? new Date(getFormControl.birthDate) : null}
      onChange={birthDate => {
        if(birthDate){
          setFormControl({ merge: { birthDate: format(birthDate, 'yyyy-MM-dd') } })
        }
      }}
      placeholder={intl.formatMessage({id: 'DateFormatPlaceholder', defaultMessage: 'JJ/MM/AAAA'})}
    />
    <PhoneNumberInput
        hasError={errors.hasError('phone')} name={'telephone'} type={'tel'}
        label={intl.formatMessage({id: 'accountEditMobileLabel', defaultMessage: 'Téléphone mobile'})} required
        value={getFormControl.phone}
        onChange={phone => setFormControl({ merge: { phone } })}
        zone={lang} 
      />

    <Flex justifyContent={['center']}>
      <Button id={'register_form_button'} type={'submit'} colorType={'outlineSecondary'}
              loading={getLoading}>
              <FormattedMessage id='register' defaultMessage='Valider'/>
      </Button>
    </Flex>
  </form>
}
