import React, {FC, FormEvent, useState} from 'react';
import {useGiftCardStore} from '../gift-card-store';
import {
    Container,
    useToaster
} from '@linkeo.com/ui-lib-react';
import {CardAuthentification} from '../../customer/authentification/components/card-authentification';
import {useAuthStore} from '../../customer/authentification/auth-store';
import {GiftCardPayment, Option} from '../gift-card.types';
import {useConfigurationStore} from '../../configuration/configuration-store';
import {AxiosError} from 'axios';
import {generatePath, Navigate} from "react-router";
import {useIntl} from 'react-intl';
import {GiftCardEntrySection} from '../components/gift-card-entry-section';
import {GiftCardValidationSection} from '../components/gift-card-validation-section';
import {PageTemplate} from '../../common/components/page-template';
import {reduceChoices, updateItemInArray} from "../../common/misc-util";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";
import {routeGiftCard, routeHome} from "../../routes";
import {usePaymentStore} from "../../subscription/payment-store";

export const GiftCardConfirmationPage: FC = () => {
    const {selectedGiftCard, updateGift, updateGiftOptions, purchaseGiftCard} = useGiftCardStore();
    const config = useConfigurationStore();
    const codeBouton = useCodeBouton();
    const {giftcard: {giftcardValidity}, appointment: {allowAnonymous}, currency, publicKey} = config;
    const {authData, logOut} = useAuthStore();
    const {showSubscriptions} = usePaymentStore();
    const toast = useToaster();
    const intl = useIntl()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [giftCardPayment, SetGiftCardPayment] = useState<GiftCardPayment | null>(null);

    if ((!allowAnonymous && !authData) || !selectedGiftCard) {
        return <Navigate to={generatePath(routeHome, {codeBouton, locale: intl.locale})}/>;
    }

    const onOptionsChange = (option: Option, index: number) => {
        if (option.max && option.choices.reduce((quantity, choice) => quantity + (choice.quantity || 0), 0) > option.max) {
            toast(intl.formatMessage({
                id: 'bookEntryCardMaxToastMessage',
                defaultMessage: 'Vous ne pouvez pas faire plus de {quantity} choix pour cette option'
            }, {quantity: option.max}))
        } else if (option.maxFree && option.choices.reduce((quantity, choice) => quantity + (choice.quantity || 0), 0) - 1 === option.maxFree) {
            toast(intl.formatMessage({
                id: 'bookEntryCardMaxFreeToastMessage',
                defaultMessage: 'Attention, à partir de {quantity} choix, chaque choix supplémentaire vous sera facturé {price} pour cette option'
            }, {
                quantity: option.maxFree, price: intl.formatNumber(option.extraCharge || 0, {
                    style: 'currency',
                    currency
                })
            }))
            updateGiftOptions({
                ...selectedGiftCard,
                options: updateItemInArray(selectedGiftCard.options, index, option)
            }).catch((err: AxiosError) => console.error(err))
        } else {
            updateGiftOptions({
                ...selectedGiftCard,
                options: updateItemInArray(selectedGiftCard.options, index, option)
            }).catch((err: AxiosError) => console.error(err))
        }
    }

    const submitFormGiftCard = (e: FormEvent) => {
        e.preventDefault()
        setIsLoading(true)
        purchaseGiftCard()
            .then((value: GiftCardPayment) => SetGiftCardPayment(value))
            .catch((err: AxiosError) => {
                if (err.response?.status === 401) {
                    toast(intl.formatMessage({
                        id: 'toastMessageSessionInvalide',
                        defaultMessage: 'Votre connexion est expirée, veuillez vous reconnecter'
                    }), {
                        type: 'error',
                        outlined: true
                    })
                    logOut()
                } else if (err.response?.status === 400) {
                    toast(intl.formatMessage({
                        id: 'DateSelectionPageOptionsErrorMessage',
                        defaultMessage: 'Vous devez sélectionner des options'
                    }))
                } else {
                    toast(intl.formatMessage({id: 'toastMessageError', defaultMessage: 'Une erreur est survenue'}))
                }
            })
            .finally(() => setIsLoading(false))
    }

    return <PageTemplate configuration={config} isLoading={!selectedGiftCard}
                         authData={authData === null ? undefined : authData}
                         codeBouton={codeBouton} showSubscriptions={showSubscriptions}
    >

        {selectedGiftCard ? <>

            <Container size={"lg"}>

                <GiftCardEntrySection
                    editServicesPath={generatePath(routeGiftCard, {codeBouton, locale: intl.locale})}
                    duration={selectedGiftCard.service.duration}
                    title={selectedGiftCard.service.title}
                    price={selectedGiftCard.service.price}
                    currency={currency}
                    optionsTotal={selectedGiftCard.options.reduce((price, option) => price + (option.total || 0), 0)}
                    total={selectedGiftCard.value}
                    options={selectedGiftCard.options.map(option => {
                        return {...option, choices: reduceChoices(option.choices)}
                    })}
                    onOptionsChange={onOptionsChange}
                    readOnly={!!giftCardPayment}
                    discountPrice={selectedGiftCard.service.giftcardDiscount ? selectedGiftCard.service.giftcardDiscount.price : undefined}
                />

                <CardAuthentification
                    authData={authData}
                    titleName={intl.formatMessage({id: 'GCbookingSummaryTitle2', defaultMessage: '2. Identification'})}
                    subtitle={intl.formatMessage({id: 'bookingSummaryAuthSubtitle', defaultMessage: 'Bienvenue !'})}
                    buttonLabel={intl.formatMessage({
                        id: 'bookingSummaryAuthCaptionLogout',
                        defaultMessage: 'Ce n’est pas votre compte ?'
                    })}
                    onClickLogOut={logOut}/>

                {authData ?

                    <GiftCardValidationSection
                        giftCardPaymentExists={!!giftCardPayment}
                        currency={currency}
                        isLoading={isLoading}
                        onChangeGiftedTo={(value) => updateGift({...selectedGiftCard, giftedTo: value})}
                        onChangeEmail={(value) => updateGift({...selectedGiftCard, email: value})}
                        onChangeMessage={(value) => updateGift({...selectedGiftCard, message: value})}
                        onSubmitFormGiftCard={submitFormGiftCard}
                        giftcardValidity={giftcardValidity}
                        publicKey={publicKey || ''}
                        options={selectedGiftCard.options.map(option => {
                            return {...option, choices: reduceChoices(option.choices)}
                        })}
                        clientSecret={giftCardPayment?.stripe.clientSecret}
                        amount={giftCardPayment?.total.amount}
                        giftCardId={giftCardPayment?.giftCard.id}
                        duration={selectedGiftCard.service.duration}
                        price={selectedGiftCard.value}
                        title={selectedGiftCard.service.title}
                        giftedTo={selectedGiftCard.giftedTo}
                        message={selectedGiftCard.message}
                        email={selectedGiftCard.email}
                        codeBouton={codeBouton}
                    />

                    : null}

            </Container>

        </> : null}

    </PageTemplate>
}
