import {AxiosRequestConfig} from 'axios';
import {Choice} from "../options/options-types";

export const notInitPromise = () => Promise.reject(new Error('Provider is not implemented and initialized'));


export const ToggleReducer = (prev: boolean): boolean => !prev;

export type DebounceHandler<T> = (...args: any) => T | Promise<T> | PromiseLike<T>;
export type DebouncedFunction<T extends DebounceHandler<U>, U> = (...args: Parameters<T>) => Promise<Awaited<U>>;

/**
 * Instancie un debounce qui permet de se faire rappeler à la fin du timeout
 * si un autre call c'est exécuté alors le timeout est réinitialisé
 * et la fonction précédente sera annulé
 * @param handler
 * @param time
 * @constructor
 */
export const CreateDebounce = <U, T extends DebounceHandler<U>>(handler: T, time: number): DebouncedFunction<T, U> => {
    let timeoutId: number;
    return (...args: Parameters<T>) => {
        window.clearTimeout(timeoutId);
        return new Promise((resolve) => {
            timeoutId = window.setTimeout(() => {
                resolve(handler(...args) as Awaited<U>);
            }, time);
        })
    };
};
CreateDebounce(() => {
    return Promise.resolve('foobar')
}, 100)
export const SetHeaderToken = (token?: string): AxiosRequestConfig | undefined => {
    if (token) {
        return {headers: {Authorization: `Bearer ${token}`}} as AxiosRequestConfig;
    }
}

export const imageExists = (uri: string): Promise<boolean> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = uri;
        if (img.complete) {
            resolve(true);
        } else {
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
        }
    });
}

export const updateItemInArray = function <T>(current: T[], index: number, value: T): T[] {
    const arr = [...current];
    arr[index] = value;
    return arr;
};

export const reduceChoices = (choices: Choice[]) => {
    const reduce = choices.reduce((acc, curr) => {
        if (acc[curr.id]) {
            const {quantity, total} = acc[curr.id]
            acc[curr.id] = {
                ...curr,
                quantity: (quantity || 0) + (curr.quantity || 0),
                total: (total || 0) + (curr.total || 0)
            };
        } else {
            acc[curr.id] = curr;
        }
        return acc
    }, {} as { [k: string]: Choice })
    return Object.values(reduce);
}

export const minimumFractionDigits = (value: number): number => {
    return value % 1 === 0 ? 0 : (value % 1).toString().length <= 3 ? 1 : 2
}

export const getTimeZoneOffset = (timezone: string): string => {
    let utcDate = new Date(new Date().toLocaleString('en-US', {timeZone: 'UTC'}))
    let timezoneDate = new Date(new Date().toLocaleString('en-US', {timeZone: timezone}))
    const timezoneOffset = (utcDate.getTime() - timezoneDate.getTime()) / 60000;
    const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60).toString().padStart(2, '0');
    const offsetMins = Math.floor(Math.abs(timezoneOffset) % 60).toString().padStart(2, '0');
    return `${timezoneOffset <= 0 ? '+' : '-'}${offsetHours}:${offsetMins}`
}



