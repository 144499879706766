import React, {FC} from 'react';
import {AuthContainer} from '../components/auth-container';
import {useAuthStore} from '../auth-store';
import {Container, InOut} from '@linkeo.com/ui-lib-react';
import {PageTemplate} from "../../../common/components/page-template";
import {useCodeBouton} from "../../../common/providers/code-bouton-provider";
import {useConfigurationStore} from "../../../configuration/configuration-store";
import {usePaymentStore} from "../../../subscription/payment-store";

export const AuthenticationPage: FC = () => {
    const {authData} = useAuthStore();
    const codeBouton = useCodeBouton();
    const config = useConfigurationStore();
    const {showSubscriptions} = usePaymentStore();

    return (
        <>
            <PageTemplate codeBouton={codeBouton} configuration={config} showSubscriptions={showSubscriptions}>
                <InOut show={!authData}>
                    <Container size={'lg'}>
                        <AuthContainer/>
                    </Container>
                </InOut>
            </PageTemplate>
        </>
    );
};
