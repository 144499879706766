import {FC, ReactNode, useEffect, useState} from 'react';
import {
    BaseButton,
    Button,
    Col,
    Flex,
    Hide,
    Row,
    SpanBody2,
    SpanCaption1,
    SpanSubtitle1, Svg,
    Switch, Tag
} from '@linkeo.com/ui-lib-react';
import styled, {useTheme} from 'styled-components';
import {SmallSvg} from '../../../common/components/small-svg';
import {FormatTime} from '../../../common/components/format-time';
import {imageExists as checkImageExists} from "../../../common/misc-util";
import {FormattedNumber, FormattedMessage, useIntl} from 'react-intl';
import {InfoPrice} from '../../../common/components/info-price';

interface ServiceCardProps {
    isUniqueSelection?: boolean;
    onSelected: (value: boolean) => void;
    selected: boolean;
    title: string;
    description: string;
    price: number;
    duration: number;
    uri?: string;
    currency: string;
    discountPrice?: number;
    downPayment?: number;
    subscription?: boolean;
}

interface ImageLoaderProps {
    uri?: string
}

const ImageLoader: FC<ImageLoaderProps> = ({uri}) => {
    const [imageExists, setImageExists] = useState<boolean>(false);
    useEffect(() => {
        if (uri) {
            checkImageExists(uri).then(exists => {
                setImageExists(exists)
            });
        }
    }, [uri]);
    let content: ReactNode;
    if (imageExists) {
        content = <div style={{
            backgroundImage: `url(${uri})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: '100%',
            width: '100%'
        }}></div>
    } else {
        content = <img src="/img/no-logo.jpg" style={{height: '100%', width: '100%'}} alt={'no logo'}/>;
    }
    return <div role="img" style={{height: '64px', width: '64px', borderRadius: '3px', overflow: 'hidden'}}>
        {content}
    </div>
}

const Desc = styled.div<{ open: boolean }>`
    max-height: ${({open}) => open ? 'auto' : '48px'};
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 5px 0;

    p {
        margin: 0 0 5px;
    }
`;
const Wrapper = styled.div`
    padding: 24px;
    border-bottom: 1px solid ${({theme}) => theme.colors.grey95};
`;

export const ServiceCard: FC<ServiceCardProps> = ({
                                                      title,
                                                      description,
                                                      price,
                                                      duration,
                                                      uri,
                                                      currency,
                                                      onSelected,
                                                      selected,
                                                      isUniqueSelection,
                                                      discountPrice,
                                                      downPayment,
                                                      subscription
                                                  }) => {

    const [open, setOpen] = useState<boolean>(false);
    const intl = useIntl();
    const {colors} = useTheme();

    return <Wrapper><Row gapColumn={[8]}>
        <Hide devices={['small', 'xs']}>
            <Col>
                <ImageLoader uri={uri}/>
            </Col>
        </Hide>
        <Col columns={[12, 12, 6, 6]}>
            <div style={{transition: "opacity 250ms ease-in-out", opacity: selected ? "1" : "0.6"}}>
                <div><SpanBody2>{title}</SpanBody2></div>
                <Desc open={open} dangerouslySetInnerHTML={{__html: description}}/>
                <BaseButton onClick={() => setOpen(!open)}><Flex alignItems={['center']}>
                    <SpanSubtitle1>
                        {open ?
                            intl.formatMessage({id: 'serviceCardLessDetails', defaultMessage: 'Moins de détails'}) :
                            intl.formatMessage({id: 'serviceCardMoreDetail', defaultMessage: 'Plus de détails'})}
                    </SpanSubtitle1>
                    <SmallSvg icon={open ? 'arrow-ios-upward-outline' : 'arrow-ios-downward-outline'}/>
                </Flex></BaseButton>
                {downPayment && downPayment > 0 ?
                    <div style={{textTransform: 'uppercase', marginTop: '5px'}}>
                        <Tag color={colors.primary} fontColor={colors.light}>
                            <Svg icon={'credit-card-outline'} fill={colors.light} width={14} height={14} style={{margin: '0px 4px -3px 0'}}/>
                            <FormattedMessage id={'serviceCardDownPaymentTag'} defaultMessage={'acompte {value}%'}
                                              values={{value: downPayment*100}}/>
                        </Tag>
                    </div> : null}
            </div>
        </Col>
        <Col columns={[12, 12, 4.5, 4.5]} style={{marginLeft: 'auto', marginTop: '18px'}}>
            <Row justifyContent={['space-between']} alignItems={['center']}>
                <Col>
                    {price > 0 && <Flex alignItems={['center']}
                           style={{marginBottom: "5px"}}>
                        <InfoPrice currency={currency} style={{marginRight: "5px"}}/>
                        <SpanBody2 style={{
                            color: isUniqueSelection && discountPrice !== undefined ? colors.grey60 : colors.grey40,
                            textDecoration: isUniqueSelection && discountPrice !== undefined ? 'line-through' : 'none'
                        }}>
                            <FormattedNumber value={price} style={`currency`} currency={currency}/>
                        </SpanBody2>
                        {isUniqueSelection && discountPrice !== undefined &&
                            <SpanBody2 style={{color: colors.success, paddingLeft: '9px'}}>
                                <FormattedNumber value={discountPrice} style={`currency`} currency={currency}/>
                            </SpanBody2>}
                    </Flex>}
                    <Flex alignItems={['center']} style={{marginRight: '5px'}}>
                        <SmallSvg icon={"clock-outline"} style={{marginRight: "5px"}}/>
                        <SpanCaption1>
                            <FormatTime minutes={duration}/>
                        </SpanCaption1>
                    </Flex>
                </Col>
                <Col className={'service_button'} style={{marginLeft: 'auto'}}>
                    {isUniqueSelection
                        ? <Button onClick={() => onSelected(!selected)}
                                  colorType={selected ? 'primary' : 'outlineSecondary'}>
                            {subscription
                                ? <FormattedMessage id='bookingButtonLabelBook' defaultMessage='Réserver'/>
                                : <FormattedMessage id='giftCardSelectionButtonConfirm' defaultMessage='Offrir'/>}
                        </Button>
                        : <Switch value={selected} onChange={onSelected}/>}
                </Col>
            </Row>
        </Col>
    </Row></Wrapper>
}
