import React from 'react';

import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ThemeProvider, ToasterProvider} from '@linkeo.com/ui-lib-react';
import {Theme} from './common/config/theme';
import {Router} from "./router";
import {ErrorBoundary} from "./common/error-boundary";
import {IntlProvider} from "./common/providers/intl.provider";
import { TimezoneProvider } from './common/providers/timezone-provider';

export const storageCodeBouton = 'codeBouton';

function App() {

    return (
        <BrowserRouter>
            <ThemeProvider theme={Theme}>
                <IntlProvider>
                    <TimezoneProvider>
                        <ErrorBoundary>
                            <ToasterProvider>
                                <Routes>
                                    <Route path={'/*'} element={<Router/>}/>
                                </Routes>
                            </ToasterProvider>
                        </ErrorBoundary>
                    </TimezoneProvider>
                </IntlProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
