import {createContext, FC, useCallback, useContext, useEffect, useMemo, useReducer, useState} from 'react';
import {
    Booking,
    BookingPayment,
    giftCardCodesStatus as GiftCardCodesStatus,
    giftCardValid,
    Option,
    Service
} from './book.types';
import {
    BookingReducer,
    BookingUpdate,
    ClearBookingActionType,
    defaultBooking,
    InitBookingOptionsActionType,
    SelectServicesActionType,
    UpdateBookingActionType,
    UpdateFromAPIResponse
} from './booking-action';
import {
    BookingResponse,
    deleteAppointment,
    getBooking,
    getBookingAvailabilities,
    getOptionsList,
    postBooking,
    postBookingPayment,
    postBookingPaymentFinalize
} from './booking.api';
import {StoreProps} from '../common/commons.types';
import {Availabilities} from "./availabilities.types";

export interface BookingContextProps {
    booking: Booking;
    bookingPayment: BookingPayment | undefined;
    calculSelectedCount: (services: Service[]) => number
    cancelAppointment: (idAppointment: string) => Promise<void>;
    updateBooking: (update: BookingUpdate) => Promise<void>;
    persistBooking: (update: BookingUpdate) => Promise<void>;
    clearBooking: () => void;
    finalize: (codes: string[]) => Promise<void>;
    setServices: (services: Service[], subscription?: boolean) => Promise<void>;
    checkGiftCardsValidity: (giftcode: string) => Promise<void>;
    removeCode: (code: GiftCardCodesStatus) => Promise<void>;
    fetchBooking: (bookingId: string) => Promise<BookingResponse>;
    getAvailabilities: (from: Date, to: Date, waitingList?: boolean) => Promise<Availabilities>;
    cancelPaymentBooking: () => void;
}

const notInitialized = () => Promise.reject(new Error('provider is not initialized'));
const dummyFn = () => {
};
const BookingContext = createContext<BookingContextProps>({
    booking: {appointments: [], reminder: {email: true, sms: true}, options: []},
    bookingPayment: {
        giftcards: [],
        onsite: {total: {amount: 0, currency: 'EUR', toPay: 0}},
        total: {amount: 0, currency: 'EUR', toPay: 0}
    },
    updateBooking: notInitialized,
    finalize: notInitialized,
    persistBooking: notInitialized,
    cancelAppointment: notInitialized,
    getAvailabilities: notInitialized,
    clearBooking: dummyFn,
    calculSelectedCount: () => 0,
    setServices: notInitialized,
    checkGiftCardsValidity: notInitialized,
    removeCode: notInitialized,
    fetchBooking: notInitialized,
    cancelPaymentBooking: notInitialized
});

class BookingHandler {

    private abortBookingCreation: (() => void) | null = null;
    private debounceId?: number;

    private _doCreateBooking(codeBouton: string, booking: Booking, token: string, dryRun: boolean) {
        let waitingList = false;
        if (!!booking.waitingList || booking.appointments.find(el => el.waitingList === true)) {
            waitingList = true;
        }
        const [promise, abort] = postBooking(codeBouton, {...booking, waitingList}, token, dryRun);
        this.abortBookingCreation = abort;
        return promise.finally(() => {
            this.abortBookingCreation = null;
        }) as Promise<BookingResponse>;
    }

    public createBooking(codeBouton: string, booking: Booking, token: string, dryRun: boolean): Promise<BookingResponse> {
        window.clearTimeout(this.debounceId);
        if (this.abortBookingCreation !== null) {
            this.abortBookingCreation()
        }
        return new Promise((resolve) => {
            this.debounceId = window.setTimeout(() => {
                resolve(this._doCreateBooking(codeBouton, booking, token, dryRun));
            })
        })
    }

    public async fetchOptions(codeBouton: string, type: string, serviceId?: string, token?: string) {
        let total;
        const limit = 20;
        let options: Option[] = [];
        do {
            const response = await getOptionsList(codeBouton, limit, options.length, type, serviceId, token);
            if (total === undefined) {
                total = response.total;
            }
            options = [...options, ...response.result]
        } while (options.length < total)
        return options;
    }
}

export const useBookingStore = () => useContext(BookingContext);
export const BookingStore: FC<StoreProps> = ({children, codeBouton, token}) => {
    const [booking, dispatchBooking] = useReducer<typeof BookingReducer>(BookingReducer, defaultBooking);
    const [paymentBooking, setPaymentBooking] = useState<BookingPayment>()
    const giftCards = useMemo(() => paymentBooking?.giftcards || [], [paymentBooking?.giftcards]);
    const handler = useMemo(() => new BookingHandler(), []);
    
    useEffect(() => {
        handler.fetchOptions(codeBouton, 'booking')
            .then(options => dispatchBooking({type: InitBookingOptionsActionType, options}))
            .catch((err) => console.error(err))
        return () => dispatchBooking({type: ClearBookingActionType})
    }, [handler, codeBouton]);
   
    const setServices = useCallback(async (services: Service[], subscription?: boolean) => {
        const bookEntries = await Promise.all(services.map(async (service) => {
            const options = (await handler.fetchOptions(codeBouton, 'service', service.id, token)).map(option => {
                return {
                    ...option, choices: option.choices.map(choice => {
                        return {...choice, quantity: 0}
                    })
                }
            });
            return {service, selected: true, spots: 1, options, subscription};
        }))
        dispatchBooking({type: SelectServicesActionType, bookEntries})
    }, [codeBouton, token, handler])

    const createBooking = useCallback(async (update: BookingUpdate) => {
        if (!token) {
            return Promise.reject(new Error('Token not found'));
        }
        dispatchBooking({type: UpdateBookingActionType, update});
        const promise = handler.createBooking(codeBouton, {...booking, ...update}, token || '', false);
        const finalizedBooking = await promise
        dispatchBooking({type: UpdateFromAPIResponse, response: finalizedBooking});
        const response = await postBookingPayment(codeBouton, [], finalizedBooking.id, token);
        setPaymentBooking(response)
    }, [handler, codeBouton, token, booking])

    const updateBooking = useCallback(async (update: BookingUpdate) => {
        dispatchBooking({type: UpdateBookingActionType, update});
        const response = await handler.createBooking(codeBouton, {
            ...booking,
            ...update,
            appointments: update.appointments?.filter(f => f.selected) || booking.appointments.filter(f => f.selected)
        }, token || '', true);
        dispatchBooking({type: UpdateFromAPIResponse, response})
    }, [handler, codeBouton, booking, token])

    const checkGiftCardsValidity = useCallback(async (giftcode: string): Promise<void> => {
        if (!token) {
            return Promise.reject(new Error('Token not found'));
        }
        if (!booking.id) {
            return Promise.reject(new Error('id not found'));
        }
        const response = await postBookingPayment(codeBouton, [...giftCards.filter(c => c.status === giftCardValid).map(e => ({code: e.code})), {code: giftcode}], booking.id, token);
        setPaymentBooking(response)
        return;
    }, [codeBouton, token, booking.id, giftCards])

    const removeCode = useCallback(async (code: GiftCardCodesStatus) => {
        if (!token) {
            return Promise.reject(new Error('Token not found'));
        }
        if (!booking.id) {
            return Promise.reject(new Error('id not found'));
        }
        let giftcardsCodes = giftCards.filter(c => c.code !== code.code)
        const response = await postBookingPayment(codeBouton, giftcardsCodes, booking.id, token)
        setPaymentBooking(response)
        return;
    }, [codeBouton, giftCards, booking.id, token])

    const finalize = useCallback(async (codes: string[]) => {
        if (!token || !booking.id) {
            return Promise.reject(new Error('Token or booking not found'));
        }
        const finalizePayment = await postBookingPaymentFinalize(codeBouton, codes.map(e => ({code: e})), booking.id, token);
        setPaymentBooking(finalizePayment)
        return;
    }, [codeBouton, token, booking.id])

    const fetchBooking = useCallback(async (bookingId: string) => {
        if (!token) {
            return Promise.reject(new Error('Token not found'));
        }
        return await getBooking(codeBouton, bookingId, token)
    }, [codeBouton, token])

    const cancelAppointment = useCallback((idAppointment: string) => {
        if (!token) {
            return Promise.reject(new Error('Token not found'));
        }
        return deleteAppointment(codeBouton, idAppointment, token)
    }, [codeBouton, token])
    
    const clearBooking = useCallback(() => {
        dispatchBooking({type: ClearBookingActionType});
        handler.fetchOptions(codeBouton, 'booking')
            .then(options => dispatchBooking({type: InitBookingOptionsActionType, options}))
            .catch((err) => console.error(err))
        setPaymentBooking(undefined)
    }, [codeBouton, handler])

    const calculSelectedCount = (services: Service[]) => booking.appointments.filter(f => services.some(s => s.id === f.service.id) && f.selected && !f.subscription).length;

    const getAvailabilities = useCallback((from: Date, to: Date, waitingList?: boolean): Promise<Availabilities> => {
        return getBookingAvailabilities(codeBouton, {...booking, waitingList}, from, to, token);
    }, [codeBouton, token, booking]);

    const cancelPaymentBooking = () => {
        setPaymentBooking(undefined)
    }

    return <BookingContext.Provider
        value={{
            finalize,
            booking,
            updateBooking,
            bookingPayment: paymentBooking,
            cancelAppointment,
            clearBooking,
            calculSelectedCount,
            setServices,
            persistBooking: createBooking,
            checkGiftCardsValidity,
            removeCode,
            fetchBooking,
            getAvailabilities,
            cancelPaymentBooking
        }}>{children}</BookingContext.Provider>
}
