import {FC, useEffect, useState} from "react";
import {PageTemplate} from "../../common/components/page-template";
import {useNavigate} from "react-router";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {usePaymentStore} from "../payment-store";
import {useIntl} from "react-intl";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";
import {generatePath, useParams} from "react-router-dom";
import {routeSubscriptionSummary} from "../../routes";

export const SubscriptionPaymentValidation: FC = () => {
    const [paymentParams, setPaymentParams] = useState<{
        payment_intent: string | null,
        payment_intent_client_secret: string | null
    }>();
    const navigate = useNavigate();
    const config = useConfigurationStore();
    const {showSubscriptions} = usePaymentStore();
    const {locale} = useIntl();
    const codeBouton = useCodeBouton();
    const {id} = useParams<{id: string}>()

    useEffect(() => {
        const search = new URLSearchParams(window.location.href);
        const payment_intent = search.get('payment_intent')
        const payment_intent_client_secret = search.get('payment_intent_client_secret')
        setPaymentParams({payment_intent, payment_intent_client_secret})
    }, [])

    useEffect(() => {
        if (!id && !paymentParams) {
            return;
        }
        navigate(generatePath(routeSubscriptionSummary, {codeBouton, locale, id}))
    }, [codeBouton, id, locale, navigate, paymentParams])

    return <PageTemplate configuration={config} showSubscriptions={showSubscriptions} isLoading={true}
    codeBouton={codeBouton}>
    </PageTemplate>
}