import React, {FC} from "react";
import {Button, Card, Col, Flex, InOut, PageTitle, Row, SpanBody2, SpanSubtitle1} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {subscribeId} from "./subscription-entry-section";
import {minimumFractionDigits} from "../../common/misc-util";
import {PaymentIntent} from "../payment-types";
import {PaymentForm} from "../../payment-stripe/payment-form";
import {generatePath} from "react-router-dom";
import {routeSubscriptionValidation} from "../../routes";

interface SubscriptionValidationSectionProps {
    name: string;
    frequency: string;
    planPrice: number;
    currency: string;
    subscriptionFees?: number | null;
    total: number;
    loading: boolean;
    paymentIntent: PaymentIntent | null;
    publicKey: string;
    codeBouton: string;
    locale: string;
}

export const SubscriptionValidationSection: FC<SubscriptionValidationSectionProps> = ({
                                                                                          name,
                                                                                          frequency,
                                                                                          planPrice,
                                                                                          currency,
                                                                                          subscriptionFees,
                                                                                          total,
                                                                                          loading,
                                                                                          paymentIntent,
                                                                                          publicKey,
                                                                                          codeBouton,
                                                                                          locale
                                                                                      }) => {
    const theme = useTheme();
    const intl = useIntl();

    return <>
        <PageTitle style={{marginTop: '32px'}}>
            <FormattedMessage id={'subscriptionValidationSectionTitle'} defaultMessage={'3. Paiement'}/>
        </PageTitle>
        <Card>
            <Flex justifyContent={['center']}>
                <SpanBody2 style={{color: theme.colors.dark}}>
                    <FormattedMessage id={'bookingDetailsSectionTitle'} defaultMessage={'Détails de votre commande'}/>
                </SpanBody2>
            </Flex>
            <Row direction={'column'} style={{width: '100%', flexWrap: 'nowrap', margin: '24px 0'}}>
                <Col style={{textAlign: 'end'}}>
                    <SpanSubtitle1>
                        1x {name} {frequency} &nbsp; <strong>{intl.formatNumber(planPrice, {
                        style: 'currency',
                        currency,
                        minimumFractionDigits: minimumFractionDigits(planPrice)
                    })}</strong>
                    </SpanSubtitle1>
                </Col>
                {subscriptionFees && <Col style={{textAlign: 'end'}}>
                    <SpanSubtitle1>
                        <FormattedMessage id={'subscriptionEntrySectionSubscriptionFeesTitle'}
                                          defaultMessage={'Frais d’inscription'}/> &nbsp; <strong><FormattedNumber
                        value={subscriptionFees} style={'currency'} currency={currency}
                        minimumFractionDigits={minimumFractionDigits(subscriptionFees)}/></strong>
                    </SpanSubtitle1>
                </Col>}
                <Col style={{textAlign: 'end', marginTop: '24px'}}>
                    <SpanSubtitle1>
                        <FormattedMessage id={'subscriptionValidationSectionTotalLabel'}
                                          defaultMessage={'Total: {total}'}
                                          values={{
                                              total: intl.formatNumber(total, {
                                                  style: 'currency',
                                                  currency,
                                                  minimumFractionDigits: minimumFractionDigits(total)
                                              })
                                          }}/>
                    </SpanSubtitle1>
                </Col>
                <Col style={{textAlign: 'end'}}>
                    <SpanSubtitle1>
                        <strong>
                            <FormattedMessage id={'subscriptionValidationSectionToPayLabel'}
                                              defaultMessage={'À régler: {total}'}
                                              values={{
                                                  total: intl.formatNumber(total, {
                                                      style: 'currency',
                                                      currency,
                                                      minimumFractionDigits: minimumFractionDigits(total)
                                                  })
                                              }}/>
                        </strong>
                    </SpanSubtitle1>
                </Col>
            </Row>
            {!paymentIntent ? <Flex justifyContent={['center']}>
                    <Button type={'submit'}
                            form={subscribeId}
                            style={{margin: ' 24px 0 16px'}}
                            colorType={"primary"}
                            loading={loading}>
                        <FormattedMessage
                            id='susbrciptionValidationSectionSubmitLabel'
                            defaultMessage='Payer {total}'
                            values={{
                                total: intl.formatNumber(total, {
                                    style: 'currency',
                                    currency,
                                    minimumFractionDigits: minimumFractionDigits(total)
                                })
                            }}/>
                    </Button>
                </Flex>
                : <InOut show={true} autoScroll startTriggering>
                    <PaymentForm
                        subscription={true}
                        clientSecret={paymentIntent.clientSecret}
                        publicKey={publicKey}
                        labelButton={intl.formatMessage({
                            id: 'susbrciptionValidationSectionSubmitLabel',
                            defaultMessage: 'Payer {total}'
                        }, {
                            total: intl.formatNumber(total, {style: 'currency', currency})
                        })}
                        redirectUrl={`${window.location.origin}${generatePath(routeSubscriptionValidation, {
                            codeBouton,
                            locale,
                            id: paymentIntent.subscription.id
                        })}`}/>
                </InOut>}
        </Card>
    </>
}