import { getTypo } from "@linkeo.com/ui-lib-react";
import styled from "styled-components";

export const Tag = styled.span`
display: inline-block;
background-color: ${({theme}) => theme.colors.primary};
padding: 2px 5px;
border-radius: 4px;
${getTypo('subtitle1')}
color: ${({theme}) => theme.colors.light};
margin: 0 10px 5px 0;

&:hover {
  background-color: ${({theme}) => theme.colors.primary};
}
`