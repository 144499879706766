import {FC} from 'react';
import {
    Button,
    ButtonSelect,
    Card,
    Col,
    Row,
    SelectOption,
    SpanBody2,
    Switch,
    useToaster,
} from '@linkeo.com/ui-lib-react';
import {useIntl} from 'react-intl';
import {PriceAndTime} from '../../common/components/price-and-time';
import styled from "styled-components";
import {OptionForm} from "../../options/options-form";
import {OptionHeader} from "../../options/options-header";
import {OptionsList} from "../../options/options-list";
import {updateItemInArray} from "../../common/misc-util";
import {Agenda, Option, PartialBookEntry} from "./booking-types";

const OptionsElement = styled(Row)`
  border-top: 1px solid #E2E6E9;
  background: rgba(0, 0, 0, 0.02);
`

interface BookEntryCardProps {
    bookEntry: PartialBookEntry;
    onChange?: (bookEntry: PartialBookEntry) => void
    enableSwitch?: boolean;
    currency: string;
    total: number;
    maxSpots: number;
    agendas: Agenda[];
    serviceDuration: number;
    servicePrice: number;
    serviceTitle: string;
    allowAgendaSelection?: boolean;
    isSubscription: boolean
}

export const BookEntryCard: FC<BookEntryCardProps> = ({
                                                          bookEntry,
                                                          onChange,
                                                          enableSwitch,
                                                          currency,
                                                          total,
                                                          maxSpots,
                                                          agendas,
                                                          serviceDuration,
                                                          servicePrice,
                                                          serviceTitle,
                                                          allowAgendaSelection,
                                                          isSubscription
                                                      }) => {
    const intl = useIntl();
    const toast = useToaster();

    const onOptionsChange = (option: Option, index: number) => {
        if (onChange) {
            if (option.max && option.choices.reduce((quantity, choice) => quantity + (choice.quantity || 0), 0) > option.max) {
                toast(intl.formatMessage({
                    id: 'bookEntryCardMaxToastMessage',
                    defaultMessage: 'Vous ne pouvez pas faire plus de {quantity} choix pour cette option'
                }, {quantity: option.max}))
            } else if (option.maxFree && option.choices.reduce((quantity, choice) => quantity + (choice.quantity || 0), 0) - 1 === option.maxFree) {
                toast(intl.formatMessage({
                    id: 'bookEntryCardMaxFreeToastMessage',
                    defaultMessage: 'Attention, à partir de {quantity} choix, chaque choix supplémentaire vous sera facturé {price} pour cette option'
                }, {
                    quantity: option.maxFree, price: intl.formatNumber(option.extraCharge || 0, {
                        style: 'currency',
                        currency
                    })
                }))
                onChange({...bookEntry, options: updateItemInArray((bookEntry.options || []), index, option)});
            } else {
                onChange({...bookEntry, options: updateItemInArray((bookEntry.options || []), index, option)});
            }
        }
    }
    const spotsOptions: SelectOption<number>[] = new Array(maxSpots).fill(0).map((_, i) => ({
        value: i + 1,
        label: intl.formatMessage({
            id: 'bookEntryCardOptionSpot',
            defaultMessage: '{count,plural, =1 {1 place} other {# places}}'
        }, {count: i + 1})
    }));
    const selectedSpot = spotsOptions.find((f) => f.value === bookEntry.spots) || spotsOptions[0];
    const agendaOptions: SelectOption<Agenda | null>[] = [{
        value: null,
        label: intl.formatMessage({id: 'bookEntryCardOptionAgendaNone', defaultMessage: 'pas de préférence'})
    }, ...agendas.map(a => ({label: a.name, value: a, uid: a.id}))];
    const agendaSelectButton = <>
        {onChange ? <ButtonSelect<Agenda | null>
            right options={agendaOptions}
            colorType={'outlineSecondary'}
            onSelect={selection => onChange({...bookEntry, agenda: selection.value})}
            selected={bookEntry.agenda ? {
                value: bookEntry.agenda,
                label: bookEntry.agenda.name,
                uid: bookEntry.agenda.id
            } : agendaOptions[0]}/> : <Button
            colorType={'outlineSecondary'}>{bookEntry.agenda ? bookEntry.agenda.name : intl.formatMessage({
            id: 'bookEntryCardNoOption',
            defaultMessage: 'sans préférence'
        })}</Button>}</>

    return <Card style={{padding: '14px 14px 0 14px', margin: '24px 0'}}>
        <Row gapColumn={[8]} alignItems={['center']} style={{padding: '10px 10px 24px'}}>
            <PriceAndTime
                duration={serviceDuration}
                currency={currency}
                price={isSubscription ? 0 : servicePrice}>
                <SpanBody2>{serviceTitle}</SpanBody2>
            </PriceAndTime>

            <Col style={{marginLeft: "auto", maxWidth: '100%'}}>
                <Row gapColumn={[4]} alignItems={['center']}>
                    {maxSpots > 1 ? <Col>
                        {onChange && !isSubscription ? <ButtonSelect<number>
                                role={'select'}
                                right
                                options={spotsOptions}
                                onSelect={selection => onChange({...bookEntry, spots: selection.value})}
                                colorType={'outlineSecondary'}
                                selected={selectedSpot}/> :
                            <Button colorType={'outlineSecondary'}>{selectedSpot.label}</Button>}
                    </Col> : null}
                    <Col style={{maxWidth: '100%'}}>
                        {allowAgendaSelection && agendaSelectButton}
                    </Col>
                    {enableSwitch && onChange ? <Col>
                        <Switch value={bookEntry.selected}
                                onChange={selected => onChange({...bookEntry, selected, subscription: isSubscription})}/>
                    </Col> : null}
                </Row>
            </Col>
        </Row>
        <div></div>
        {bookEntry.selected && bookEntry.options && bookEntry.options.length > 0 && <OptionsElement>
            <Row justifyContent={'space-between'} style={{padding: '26px'}}>
                <OptionHeader title={intl.formatMessage({id: 'BookEntryCardOptionsTitle', defaultMessage: 'Options'})}
                              total={intl.formatNumber(total, {
                                  style: 'currency',
                                  currency
                              })}
                />
            </Row>
            {onChange ? bookEntry.options.map((option, index) => {
                return <Row key={index}
                            style={{
                                borderTop: '1px solid #E2E6E9',
                                width: '94%',
                                margin: '0 26px',
                                paddingTop: '24px'
                            }}>
                    <OptionForm {...option} currency={currency}
                                onChoicesChange={(choices) => onOptionsChange({...option, choices}, index)}/>
                </Row>
            }) : bookEntry.options.map((option, index) => {
                return <OptionsList {...option} key={index} currency={currency}/>
            })}
        </OptionsElement>}
    </Card>
}
