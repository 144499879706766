import React, {FC, useEffect, useState} from 'react';
import {BlockCategory} from "../../booking/components/service/block-category";
import {useGiftCardStore} from '../gift-card-store';
import {Service} from '../../service/service.types';
import {useServicesStore} from '../../service/services-store';
import {routeGiftCardConfirmation, routeHome} from '../../routes';
import {generatePath, Navigate, useNavigate} from "react-router";
import {useConfigurationStore} from '../../configuration/configuration-store';
import {useAuthStore} from "../../customer/authentification/auth-store";
import {useIntl} from 'react-intl';
import {ServiceSelectionSection} from '../../booking/components/service/service-selection-section';
import {PageTemplate} from '../../common/components/page-template';
import {AxiosError} from "axios";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";
import {usePaymentStore} from "../../subscription/payment-store";

export const GiftCardSelectionPage: FC = () => {
    const {selectedGiftCard, selectGift} = useGiftCardStore();
    const intl = useIntl();
    const {categories, fetchData} = useServicesStore();
    const config = useConfigurationStore();
    const {showSubscriptions} = usePaymentStore();
    const {appointment: {allowAnonymous}, giftcard} = config;
    const {authData} = useAuthStore();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)
    const codeBouton = useCodeBouton();

    useEffect(() => {
        fetchData().catch((err: AxiosError) => console.error(err))
        return () => setLoading(false)
    }, [fetchData])

    if ((!allowAnonymous && !authData) || !giftcard.active) {
        return <Navigate to={generatePath(routeHome, {codeBouton, locale: intl.locale})}/>;
    }

    const onClickOfferGiftCard = (service: Service) => {
        setLoading(true)
        selectGift(service)
            .then(() => navigate(generatePath(routeGiftCardConfirmation, {codeBouton, locale: intl.locale})))
            .catch((err: AxiosError) => console.error(err))
            .finally(() => setLoading(false))
    }

    return <PageTemplate configuration={config} isLoading={!categories || loading} codeBouton={codeBouton} showSubscriptions={showSubscriptions}>
        {categories ? <ServiceSelectionSection title={intl.formatMessage({
            id: 'giftCardSelectionSubtitle',
            defaultMessage: 'Choisissez et sélectionnez la(s) prestation(s) que vous souhaitez offrir'
        })}>
            {Object.keys(categories).sort().map((cat) => <BlockCategory
                isUniqueSelection={true}
                onSelectedService={onClickOfferGiftCard}
                selectedServices={selectedGiftCard ? [selectedGiftCard.service] : []}
                key={cat} categoryTitle={cat}
                services={categories[cat].services}
                minPrice={categories[cat].minPrice}
                maxDuration={categories[cat].maxDuration}
                minDuration={categories[cat].minDuration}
                selectedCount={categories[cat].services.find(e => e.id === selectedGiftCard?.service.id) ? 1 : 0}
                description={categories[cat].description}
                paymentActive={config.giftcard.active}
                currency={config.currency}
            />)}
        </ServiceSelectionSection> : null}
    </PageTemplate>

}
