import { FC } from "react";
import { SpanBody1, Row, Col, SpanBody2, BaseButton } from "@linkeo.com/ui-lib-react";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { minimumFractionDigits } from "../../../common/misc-util";
import { AccountCard } from "./common";

const Light = styled(SpanBody1)`
    font-size: 13px;
`
const Hr = styled.hr`
    border: 1px solid #E2E5E9;
    border-bottom: none;
`
const MarginRow = styled(Row)`
    padding: 8px 0 8px;
`
const DotCol = styled(Col)`
    flex: 1;
    border-bottom: 1px dotted #E2E5E9;
    padding: 0;
`
const ServicesList = styled.ul<{ size: number }>`
    display: grid;
    grid-template-columns: ${({ size }) => size > 5 ? 'repeat(auto-fill, minmax(50%, 1fr))' : '100%'};

    @media (max-width: 768px) {
        grid-template-columns: 100%;
    };
`

interface SubscriptionDisplayConditionsProps {
    conditionsName: string
    conditionsText?: string
}

const SubscriptionDisplayConditions: FC<SubscriptionDisplayConditionsProps> = ({ conditionsName, conditionsText }) => {
    const intl = useIntl();

    return <MarginRow justifyContent={'space-between'}>
        <Col>
            <SpanBody2>
                {conditionsName}
            </SpanBody2>
        </Col>
        <DotCol />
        <Col>
            <SpanBody1>
                {conditionsText
                    ?? intl.formatMessage({ id: 'subscriptionsListingPageNoConditions', defaultMessage: 'aucune' })}
            </SpanBody1>
        </Col>
    </MarginRow>
}

interface SubscriptionDisplayCardProps {
    name: string;
    date: string;
    price: number;
    frequency: string;
    currency: string;
    services: (string | undefined)[];
    subscriptionConditions?: string;
    unsubscriptionConditions?: string;
    otherSubscriptionConditions?: string;
    onSubscriptionCancelClick: () => void;
}

export const SubscriptionDisplayCard: FC<SubscriptionDisplayCardProps> = ({
    name,
    date,
    price,
    frequency,
    currency,
    services,
    subscriptionConditions,
    unsubscriptionConditions,
    otherSubscriptionConditions,
    onSubscriptionCancelClick
}) => {
    const intl = useIntl();

    return <AccountCard icon={'bookmark-outline'}
        titleName={name}>
        <Row style={{ padding: '10px 0' }} justifyContent={'space-between'}>
            <Col >
                <div className="title">
                    <SpanBody1>
                        <FormattedMessage id={'accountSubscriptionsPageSubscriber'} defaultMessage={'Abonné'} />
                    </SpanBody1>
                </div>
                <div className="subscription-date">
                    <SpanBody2>
                        <FormattedMessage
                            id={'accountSubscriptionsPageSubscriberSince'}
                            defaultMessage={'depuis le {date}'}
                            values={{ date: intl.formatDate(date, { dateStyle: 'short' }) }} />
                    </SpanBody2>
                </div>
            </Col>
            <Col style={{ textAlign: 'right' }}>
                <div className="amount-label">
                    <Light>
                        <FormattedMessage id={'accountSubscriptionPriceTitle'} defaultMessage={'Tarif'} />
                    </Light>
                </div>
                <div className="amount-value">
                    <SpanBody2>
                        <FormattedMessage
                            id={'accountSubscriptionPriceValue'}
                            defaultMessage={'{price}/{frequency}'}
                            values={{
                                price: intl.formatNumber(price,
                                    {
                                        style: 'currency',
                                        currency,
                                        minimumFractionDigits: minimumFractionDigits(price)
                                    }),
                                frequency
                            }} />
                    </SpanBody2>
                </div>
            </Col>
        </Row>
        <Hr />
        <SubscriptionDisplayConditions
            conditionsName={intl.formatMessage({ id: 'subscriptionListingPageSubscriptionConditions', defaultMessage: 'Conditions d\'inscription' })}
            conditionsText={subscriptionConditions} />
        <SubscriptionDisplayConditions
            conditionsName={intl.formatMessage({ id: 'subscriptionListingPageUnsubscriptionConditions', defaultMessage: 'Conditions de désinscription' })}
            conditionsText={unsubscriptionConditions} />
        <SubscriptionDisplayConditions
            conditionsName={intl.formatMessage({ id: 'subscriptionListingPageOtherSubscriptionConditions', defaultMessage: 'Autres conditions d’abonnement' })}
            conditionsText={otherSubscriptionConditions} />
        <Row style={{ padding: '16px 0 8px' }}>
            <Col>
                <SpanBody2>
                    <FormattedMessage
                        id={'subscriptionListingPageServicesListTitle'}
                        defaultMessage={'Prestations accessibles'} />
                </SpanBody2>
            </Col>
        </Row>
        <ServicesList size={services.length}>
            {services.map((el, index) => {
                if (el === undefined) {
                    return null
                } else {
                    return <li key={index}>
                        <SpanBody1>
                            {el}
                        </SpanBody1>
                    </li>
                }
            })}
        </ServicesList>
        <BaseButton style={{ margin: '16px 0' }}>
            <SpanBody1 style={{ textDecorationLine: 'underline' }} onClick={onSubscriptionCancelClick}>
                <FormattedMessage id='subscriptionListingPageCancelSubscriptionButtonLabel' defaultMessage='Annuler le renouvellement' />
            </SpanBody1>
        </BaseButton>
    </AccountCard >
}