import {Payment, Total} from '../common/commons.types';

export interface Choice {
    id: string;
    label: string;
    price?: number;
    quantity?: number;
    total?: number;
}

export interface Option {
    id: string;
    name: string;
    type: string;
    min: number;
    max: number | null;
    countable: boolean;
    extraCharge?: number;
    maxExtra?: number;
    maxFree?: number;
    choices: Choice[];
    total?: number
}

export interface Agenda {
    id: string;
    name: string;
}

export interface Service {
    agendas: Agenda[];
    description: string;
    duration: number;
    id: string;
    price: number;
    maxSpotsPerBookEntry: number;
    title: string;
    options?: Option[],
    downPayment?: number
}

export type ContactMediaType = "sms" | "email";
export type BookingReminder = {
    [key in ContactMediaType]: boolean
}

export interface BookEntry {
    id?: string;
    service: Service;
    spots: number;
    agenda?: Agenda | null;
    selected: boolean;
    options?: Option[];
    optionsTotal?: number;
    waitingList?: boolean;
    subscription?: boolean;
}

export interface Booking {
    appointments: BookEntry[];
    id?: string;
    date?: string;
    notes?: string;
    reminder: BookingReminder;
    totalToPay?: number;
    options?: Option[];
    optionsTotal?: number;
    total?: number;
    waitingList?: boolean
}

export const giftCardValid = 'valid'
export const giftCardInvalid = 'invalid'

type GiftCardStatus = typeof giftCardValid | typeof giftCardInvalid

export interface GiftCardsCodes {
    code: string,
}

export interface giftCardCodesStatus {
    code: string;
    status?: GiftCardStatus;
    total?: Total
}

export interface BookingPayment extends Omit<Payment, 'stripe'> {
    giftcards: giftCardCodesStatus[];
    subscriptions?: SubscriberSubscriptionPayment[]
    stripe?: {
        clientSecret: string,
        total: Total
    }
}

export interface SubscriberSubscriptionPayment {
    subscription: SubscriberSubscription;
    total?: Total
}

const pending = "pending";
const active = "active";
const canceled = "canceled";
const error = "error";

interface SubscriptionPlan {
    id: string;
    price?: number|null;
    tacitRenewal?: boolean|null;
    active: boolean|null;
    numberOfMonths: number
}

export interface SubscriberSubscription {
    id: string;
    name: string;
    createdAt: string;
    activatedAt: string;
    status: typeof pending|typeof active|typeof canceled|typeof error;
    plan: SubscriptionPlan;
    services: ServiceId[];
    currentPeriodEnd?: string|null;
    subscriptionFormulaId: string;
}

interface ServiceId {
    id: string;
}
