import {FC, useEffect, useRef, useState} from "react";
import {PageTemplate} from "../../common/components/page-template";
import {active, error, SubscriberSubscription} from "../payment-types";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";
import {useAuthStore} from "../../customer/authentification/auth-store";
import {usePaymentStore} from "../payment-store";
import {Col, Container, Row, SpanBody1, SpanH6} from "@linkeo.com/ui-lib-react";
import {generatePath, useParams} from "react-router-dom";
import {ReactComponent as SuccessIcon} from '../../icons/success.svg';
import {ReactComponent as FailIcon} from '../../icons/fail.svg';
import {ReactComponent as UnknownIcon} from '../../icons/unknown.svg';
import {FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {LinkPrimary} from "../../common/components/link-primary";
import {routeAppointment, routeSubscriptionConfirmationProposal} from "../../routes";

export const SubscriptionSummaryPage: FC = () => {
    const config = useConfigurationStore();
    const codeBouton = useCodeBouton();
    const {locale} = useIntl();
    const {id} = useParams();
    const theme = useTheme();
    const {showSubscriptions, getSubscription} = usePaymentStore();
    const {authData} = useAuthStore();
    const [subscription, setSubscription] = useState<SubscriberSubscription>();
    const [loading, setLoading] = useState<boolean>(true);
    const intervalId = useRef<NodeJS.Timer | null>(null)
    const timeoutId = useRef<NodeJS.Timer | null>(null)

    const clearIntervalId = () => {
        if (intervalId.current) {
            clearInterval(intervalId.current);
            intervalId.current = null
        }
    }

    const clearTimeoutId = () => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
            timeoutId.current = null;
        }
    }

    useEffect(() => {
        setLoading(true);
        if (!timeoutId.current) {
            timeoutId.current = setTimeout(async () => {
                try {
                    const sub = await getSubscription(id || '');
                    setSubscription(sub);
                    clearTimeoutId();
                    clearIntervalId()
                } catch (e) {
                    console.error(e);
                    clearTimeoutId();
                    clearIntervalId()
                } finally {
                    setLoading(false);
                }
            }, 30000)
        }
        if (!intervalId.current) {
            intervalId.current = setInterval(async () => {
                try {
                    const sub = await getSubscription(id || '')
                    if (sub.status === active || sub.status === error) {
                        setSubscription(sub);
                        clearIntervalId();
                        clearTimeoutId();
                        setLoading(false)
                    }
                } catch (e) {
                    console.error(e);
                    clearTimeoutId();
                    clearIntervalId();
                    setLoading(false)
                }
            }, 5000)
        }
        return () => {
            clearIntervalId();
            clearTimeoutId()
        }
    }, [getSubscription, id])

    return <PageTemplate configuration={config} isLoading={loading} codeBouton={codeBouton}
                         showSubscriptions={showSubscriptions}
                         authData={authData === null ? undefined : authData}>
        <Container size={'lg'}>
            <Row direction={'column'} alignItems={'center'} style={{marginTop: '24px', gap: '24px'}}>
                <Col>
                    {subscription?.status === active
                        ? <SuccessIcon/>
                        : subscription?.status === error ? <FailIcon/>
                            : <UnknownIcon/>}
                </Col>
                <Col>
                    <SpanH6>
                        {subscription?.status === active
                            ? <FormattedMessage id={`subscriptionValidationPageTitleStatusActive`}
                                                defaultMessage={'Merci pour votre abonnement !'}/>
                            : subscription?.status === error ?
                                <FormattedMessage id={'subscriptionValidationPageTitleStatusError'}
                                                  defaultMessage={'Échec du paiement'}/>
                                : <FormattedMessage id={'subscriptionValidationPageTitleStatusUnknown'}
                                                    defaultMessage={'Erreur lors de la validation de l\'abonnement'}/>}
                    </SpanH6>
                </Col>
                <Col>
                    <SpanBody1>
                        {subscription?.status === active
                            ? <FormattedMessage id={`subscriptionValidationPageSubtitleStatusActive`}
                                                defaultMessage={'Votre paiement a été validé avec succès et votre abonnement est activé.'}/>
                            : subscription?.status === error ?
                                <FormattedMessage id={'subscriptionValidationPageSubtitleStatusError'}
                                                  defaultMessage={'Votre paiement n\'a pas pu être traité. Veuillez vérifier vos informations bancaires ou essayer une autre méthode de paiement.'}/>
                                : <FormattedMessage id={'subscriptionValidationPageSubtitleStatusUnknown'}
                                                    defaultMessage={'Merci de contacter le commerçant pour obtenir plus d’informations.'}/>}
                    </SpanBody1>
                </Col>
                <Col style={{width: '100%', borderBottom: `1px solid ${theme.colors.grey80}`}}>
                </Col>
                {subscription?.status === active && <Col>
                    <SpanBody1>
                        <FormattedMessage id={'subscriptionValidationPageCaptionStatusActive'}
                                          defaultMessage={'Vous pouvez dès à présent profiter de votre abonnement !'}/>
                    </SpanBody1>
                </Col>}
                <Col>
                    {subscription?.status === active
                        ? <LinkPrimary to={generatePath(routeAppointment, {codeBouton, locale})}>
                            <FormattedMessage id={'subscriptionValidationPageButtonLabelStatusActive'}
                                              defaultMessage={'réserver une prestation'}/>
                        </LinkPrimary>
                        : subscription?.status === error ?
                            <LinkPrimary to={generatePath(routeSubscriptionConfirmationProposal, {
                                codeBouton,
                                locale,
                                subscriptionId: subscription.subscriptionFormulaId,
                                planId: subscription.plan.id
                            })}>
                                <FormattedMessage id={'subscriptionValidationPageButtonLabelStatusError'}
                                                  defaultMessage={'Réessayer'}/>
                            </LinkPrimary>
                            : null}
                </Col>
            </Row>
        </Container>
    </PageTemplate>
}