import {Dispatch, FC, FormEvent, SetStateAction, useState} from "react"
import {
    Container,
    PageTitle,
    Card,
    Row,
    Col,
    SpanBody2,
    SpanSubtitle1,
    H4,
    Textarea,
    Checkbox,
    Button
} from "@linkeo.com/ui-lib-react"
import {FormattedMessage, useIntl} from "react-intl"
import {CountryCode, formatIncompletePhoneNumber, parsePhoneNumber} from "libphonenumber-js";
import {useLocale} from "../../common/providers/intl.provider";

interface BookConfirmEditorProps {
    messageTitle: string;
    notes: string;
    labelButton: string;
    onClickConfirmingBooking: () => Promise<void>;
    isLoading: boolean;
    readOnly: boolean;
    email: boolean;
    sms: boolean;
    onNotesChange: (notes: string) => void;
    setSms: Dispatch<SetStateAction<boolean>>;
    setEmail: Dispatch<SetStateAction<boolean>>;
    mandatory: boolean
}

const BookConfirmEditor: FC<BookConfirmEditorProps> = ({
                                                           messageTitle,
                                                           labelButton,
                                                           onClickConfirmingBooking,
                                                           isLoading,
                                                           readOnly,
                                                           notes,
                                                           email,
                                                           sms,
                                                           onNotesChange,
                                                           setSms,
                                                           setEmail,
                                                           mandatory
                                                       }) => {
    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        onClickConfirmingBooking();
    }

    return <form onSubmit={_onSubmit}>
        <Textarea
            readOnly={readOnly}
            value={notes || ""}
            placeholder={messageTitle}
            label={messageTitle}
            required={mandatory}
            onChange={(notes) => onNotesChange(notes)}/>
        {!readOnly ? <>
            <Row justifyContent={['space-between']}>
                <Col columns={[12, 5]}>
                    <Checkbox readOnly={readOnly}
                              onChange={setEmail}
                              value={email}>
                        <FormattedMessage id='bookingSummaryEmailReminderLabel'
                                          defaultMessage='Je souhaite recevoir un E-mail de rappel 24h avant le début de ma réservation.'/>
                    </Checkbox>
                </Col>
                <Col columns={[12, 5]}>
                    <Checkbox readOnly={readOnly}
                              onChange={setSms} value={sms}>
                        <FormattedMessage id='bookingSummarySmsReminderLabel'
                                          defaultMessage='Je souhaite recevoir un SMS de rappel 1h avant le début de ma réservation.'/>
                    </Checkbox>
                </Col>
            </Row>
            <Row justifyContent={['center']}>
                <Col>
                    <Button colorType={'primary'}
                            id={'confirm_button'}
                            type={'submit'}
                            icon={"arrow-ios-forward-outline"}
                            loading={isLoading}
                            iconRight>{labelButton}</Button>
                </Col>
            </Row> </> : null}
    </form>
}

interface CustomerAuth {
    email: string,
    firstName: string,
    lastName: string,
    phone: string
}

interface BookConfirmSectionProps {
    authData: CustomerAuth;
    count: number;
    total: string;
    isLoading: boolean;
    labelButton: string;
    onClickConfirmingBooking: (notes: string, sms: boolean, email: boolean) => Promise<void>;
    readOnly: boolean;
    notes: string;
    sms: boolean;
    email: boolean;
    messageToCustomers: string;
    mandatory: boolean;
    isSubscription: boolean;
    currency: string
}

export const BookConfirmSection: FC<BookConfirmSectionProps> = ({
                                                                    authData,
                                                                    count,
                                                                    total,
                                                                    labelButton,
                                                                    onClickConfirmingBooking,
                                                                    isLoading,
                                                                    readOnly,
                                                                    sms: bookingSms,
                                                                    email: bookingEmail,
                                                                    notes: bookingNotes,
                                                                    messageToCustomers,
                                                                    mandatory,
                                                                    isSubscription,
                                                                    currency
                                                                }) => {

    const intl = useIntl();
    const {locale} = useLocale();
    const country = locale.split('-')[1];
    const [notes, setNotes] = useState<string>(bookingNotes);
    const [sms, setSms] = useState<boolean>(bookingSms);
    const [email, setEmail] = useState<boolean>(bookingEmail)

    return <Container size={"lg"}>
        <PageTitle>
            <FormattedMessage id='bookingSummaryTitle4' defaultMessage='4. Confirmation du rendez-vous'/>
        </PageTitle>
        <Card>
            <Row justifyContent={['space-between']}>
                <Col>
                    <div>
                        <SpanBody2>
                            <FormattedMessage id='bookingSummaryAdressSubtitle'
                                              defaultMessage='Vos coordonnées'/>
                        </SpanBody2>
                    </div>
                    <div>
                        <SpanSubtitle1>
                            {authData.lastName} {authData.firstName} - {authData.email} - {formatIncompletePhoneNumber(parsePhoneNumber(authData.phone, country as CountryCode).format('E.164'))}
                        </SpanSubtitle1>
                    </div>
                </Col>
                <Col style={{textAlign: 'right'}}>
                    <div>
                        <SpanSubtitle1>
                            <FormattedMessage id='bookingSummaryPrestationCount'
                                              defaultMessage='{count,plural, =1 {1 prestation} other {# prestations}}'
                                              values={{count}}/>
                        </SpanSubtitle1>
                    </div>
                    <H4>
                        {!isSubscription ? <FormattedMessage id='bookingSummaryTotal' defaultMessage='Total: {total}' values={{
                            total: total
                        }}/> : <FormattedMessage id='bookingSummarySubscriptionTotal' defaultMessage='À régler: {total}' values={{
                            total: intl.formatNumber(0, {style: 'currency', currency})
                        }} />}
                    </H4>
                </Col>
            </Row>

            <pre style={{ whiteSpace: 'pre-wrap' }}>
                <SpanSubtitle1>{messageToCustomers}</SpanSubtitle1>
            </pre>

            <BookConfirmEditor messageTitle={intl.formatMessage({id: 'MessageTitle', defaultMessage: 'Votre message'})}
                               labelButton={labelButton}
                               onClickConfirmingBooking={() => onClickConfirmingBooking(notes, sms, email)}
                               isLoading={isLoading}
                               readOnly={readOnly}
                               notes={notes}
                               email={email}
                               sms={sms}
                               mandatory={mandatory}
                               onNotesChange={setNotes}
                               setSms={setSms}
                               setEmail={setEmail}
            />
        </Card>
    </Container>
}