import React, {createContext, FC, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import {Timezones} from "./timezones";

interface TimezoneContextProps {
    timezone: string | null;
    updateTimezone: (newTimezone: string) => void;
    timezoneList: string[];
}

const messageError = 'Not initialized';

const TimezoneContext = createContext<TimezoneContextProps>({
    timezone: '',
    updateTimezone: () => console.error(messageError),
    timezoneList: []
})


export const useTimezone = () => useContext(TimezoneContext);

interface TimezoneProviderProps {
    children: ReactNode;
}

export const TimezoneProvider: FC<TimezoneProviderProps> = ({children}) => {
    const timezoneDefault = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [timezone, setTimezone] = useState<string | null>(timezoneDefault);
    const [timezoneList, setTimezoneList] = useState<string[]>([]);

    useEffect(() => {
        setTimezoneList(Timezones.filter((timezone) => {
            try {
                new Date(new Date().toLocaleString('en-US', {timeZone: timezone}))
                return true;
            } catch (e) {
                return false;
            }
        }))
    }, [])

    const updateTimezone = useCallback((newTimezone: string) => {
        setTimezone(newTimezone);
    }, []);

    return (
        <TimezoneContext.Provider
            value={{
                timezone,
                updateTimezone,
                timezoneList
            }}>
            {children}
        </TimezoneContext.Provider>
    );
};
