import { Availabilities } from './availabilities.types';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { formatISO } from 'date-fns';
import { appointmentsApiUri, servicesApiUri } from '../common/config/uri-api';
import { SetHeaderToken } from '../common/misc-util';
import { Pagination } from "../common/commons.types";
import { Agenda, Service, Option, BookingReminder, BookingPayment, GiftCardsCodes } from './book.types';

export interface BookEntryPayload {
  service: Service;
  spots: number;
  agenda?: Agenda | null;
  options?: Option[];
  optionsTotal?: number;
  waitingList?: boolean
}

export interface BookingPayload {
  appointments: BookEntryPayload[];
  date?: string;
  notes?: string;
  reminder: BookingReminder;
  totalToPay?: number;
  options?: Option[];
  optionsTotal?: number;
  total?: number;
  waitingList?: boolean
}

export interface BookEntry extends BookEntryPayload {
  id: string;
}

export interface BookingResponse extends BookingPayload {
  appointments: BookEntry[];
  id: string;
  total: number;
}

export const getOptionsList = async (codeBouton: string, limit: number, offset: number, type: string, serviceId?: string, token?: string): Promise<Pagination<Option>> => {
  const response = await axios.get(`${servicesApiUri}/${codeBouton}/options?offset=${offset}&limit=${limit}&type=${type}${serviceId ? `&serviceId=${serviceId}` : ''}`, SetHeaderToken(token))
  return response.data
}

export const getBookingAvailabilities = async (codeBouton: string, body: BookingPayload, from: Date | number, to: Date | number, token?: string): Promise<Availabilities> => {
  const config: AxiosRequestConfig = {
    params: {
      from: formatISO(from),
      to: formatISO(to)
    },
    timeout: 60000
  };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  const response = await axios.post(`${appointmentsApiUri}/${codeBouton}/availability`, body, config);
  return response.data;
}

export const getBooking = async (codeBouton: string, bookingId: string, token: string): Promise<BookingResponse> => {
  const response = await axios.get(`${appointmentsApiUri}/${codeBouton}/me/booking/${bookingId}`, SetHeaderToken(token))
  return response.data
}

export const postBooking = (codeBouton: string, body: BookingPayload, token?: string, dryRun: boolean = false): [Promise<BookingResponse>, ()=>void] => {
  const controller = new AbortController();
  const headers: AxiosRequestHeaders = { Authorization: `Bearer ${token}` }
  if (dryRun) {
    headers['Dry-Run'] = 'true'
  }
  const promise = axios.post(`${appointmentsApiUri}/${codeBouton}/me/booking`, body, { headers, signal: controller.signal });
  return [promise.then(resp => resp.data), () => controller.abort()]
}

export const postBookingPayment = async (codeBouton: string, giftcards: GiftCardsCodes[], bookingId: string, token?: string): Promise<BookingPayment> => {
  const response = await axios.post(`${appointmentsApiUri}/${codeBouton}/me/booking/${bookingId}/payment`, { giftcards }, SetHeaderToken(token));
  return response.data;
}

export const postBookingPaymentFinalize = async (codeBouton: string, giftcards: GiftCardsCodes[], bookingId: string, token?: string): Promise<BookingPayment> => {
  const response = await axios.post(`${appointmentsApiUri}/${codeBouton}/me/booking/${bookingId}/payment/finalize`, { giftcards }, SetHeaderToken(token));
  return response.data;
}

export const deleteAppointment = async (codeBouton: string, appointmentId: string, token: string): Promise<void> => {
  let config: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } };
  const response = await axios.delete(`${appointmentsApiUri}/${codeBouton}/me/appointment/${appointmentId}`, config);
  return response.data;
}
