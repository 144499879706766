import React, {FC, useEffect, useMemo, useState} from "react";
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {routeError, routeErrorNotFound, routePageNotFound} from "./routes";
import {IndexPage as BookingIndexPage} from "./booking/pages/index-page";
import {IndexPage as GiftCardIndexPage} from "./gift-card/pages/index-page";
import {IndexPage as SubscriptionIndexPage} from "./subscription/pages/index-page";
import {ResetPasswordPage} from "./customer/authentification/pages/reset-password-page";
import {PageIndex} from "./customer/account/pages/page-index";
import {AppointmentsListingPage} from "./customer/account/pages/page-appointments-listing";
import {GiftCardsListingPage} from "./customer/account/pages/page-gift-cards";
import {ErrorPage} from "./common/pages/error-page";
import {Page404} from "./common/pages/404-page";
import {AuthCallback} from "./customer/authentification/pages/auth-callback";
import {generatePath} from "react-router";
import {AuthStore, defaultAuthStorage} from "./customer/authentification/auth-store";
import {AccountProvider} from "./customer/account/account-store";
import {GiftCardStore} from "./gift-card/gift-card-store";
import {BookingStore} from "./booking/booking-store";
import {ConfigurationStore} from "./configuration/configuration-store";
import {IntlProvider, SupportedLanguage} from "./common/providers/intl.provider";
import {AxiosError} from "axios";
import {storageCodeBouton} from "./app";
import {CustomerLegalNoticePage, GlobalLegalNoticePage} from "./common/pages/legal-notice";
import {CodeboutonProvider} from "./common/providers/code-bouton-provider";
import {ConfirmProvider} from "./common/providers/confirm-provider";
import {useIntl} from "react-intl";
import {TermsOfSalesPage} from "./common/pages/terms-of-sales";
import {PaymentStore} from "./subscription/payment-store";

export const routeHome = '/';
export const routeBookingIndex = '/appointment/*';
export const routeAppointment = '/appointment';
export const routeGiftCardIndex = '/giftcard/*';
export const routeSubscriptionIndex = '/subscription/*';

export const routeResetpassword = '/resetpassword';
export const routeUserAccount = '/account/*';
export const routeUserAccountAppointmentsList = '/account/appointments';
export const routeCustomerLegalNotice = '/legal-notice';
export const routeCustomerTermsOfSales = '/terms-of-sales';
export const routeAuthCallback = '/auth/callback';

const SHOW_HEADER = 'showHeader'
let __redirectLegacy__ = false

const LEGACY_REDIRECT_INHIBITION_PARAM = 'noLegacyRedirect';
const shouldRedirectLegacy = (params: URLSearchParams): boolean => {
    const param = params.get(LEGACY_REDIRECT_INHIBITION_PARAM);
    __redirectLegacy__ = (param === null);
    return __redirectLegacy__;
}

interface CustomerRouterProps {
}

export const CustomerRouter: FC<CustomerRouterProps> = () => {
    const params = useParams();
    const codeBouton = params['codeBouton'] || '';
    const locale = params['locale'];
    const [token, setToken] = useState<string>()
    const [showHeader, setShowHeader] = useState(true);
    const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);

    useEffect(() => {
        const showHeaderStorage = sessionStorage.getItem(SHOW_HEADER);
        if (showHeaderStorage) {
            setShowHeader(showHeaderStorage !== 'false')
            return;
        }
        const queryParams = new URLSearchParams(window.location.search);
        const param = queryParams.get(SHOW_HEADER);
        if (param) {
            setShowHeader(param !== 'false');
            sessionStorage.setItem(SHOW_HEADER, param)
        }
    }, [])

    useEffect(() => {
        if (codeBouton) {
            sessionStorage.setItem(storageCodeBouton, codeBouton)
        }
    }, [codeBouton]);

    useEffect(() => {
        const data = defaultAuthStorage.getAuthData(codeBouton);
        if (!data?.accessToken) {
            return;
        }
        setToken(data.accessToken.token)
    }, [token, codeBouton])

    const onTokenChange = (token: string) => {
        setToken(token)
    }
    const onError = (err: any) => {
        if (err instanceof AxiosError && (err.response?.status === 401 || err.response?.status === 403)) {
            defaultAuthStorage.clearAuthData(codeBouton)
            setToken(undefined)
        }
    }

    return <IntlProvider locale={locale as SupportedLanguage}>
        <ConfirmProvider>
            <CodeboutonProvider codeBouton={codeBouton}>
                <ConfigurationStore codeBouton={codeBouton} locale={locale as SupportedLanguage} showHeader={showHeader}
                                    redirectLegacy={shouldRedirectLegacy(searchParams)}>
                    <AuthStore codeBouton={codeBouton} onTokenChange={onTokenChange}>
                        <PaymentStore codeBouton={codeBouton} token={token}>
                            <AccountProvider codeBouton={codeBouton} token={token} onError={onError}>
                                <GiftCardStore codeBouton={codeBouton} token={token}>
                                    <BookingStore codeBouton={codeBouton} token={token}>
                                        <Routes>
                                            <Route path={routeBookingIndex} element={<BookingIndexPage/>}/>
                                            <Route path={routeGiftCardIndex} element={<GiftCardIndexPage/>}/>
                                            <Route path={routeSubscriptionIndex} element={<SubscriptionIndexPage/>} />
                                            <Route path={routeResetpassword} element={<ResetPasswordPage/>}/>
                                            <Route path={routeUserAccount} element={<PageIndex/>}/>
                                            <Route path={routeUserAccountAppointmentsList}
                                                   element={<AppointmentsListingPage/>}/>
                                            <Route path={routeUserAccount} element={<GiftCardsListingPage/>}/>
                                            <Route path={routeError} element={<ErrorPage/>}/>
                                            <Route path={routeErrorNotFound} element={<Page404/>}/>
                                            <Route path={routePageNotFound} element={<Page404/>}/>
                                            <Route path={routeAuthCallback} element={<AuthCallback/>}/>
                                            <Route path={routeCustomerTermsOfSales} element={<TermsOfSalesPage/>}/>
                                            <Route path={routeHome} element={<Navigate
                                                to={generatePath(`/:codeBouton/:locale${routeAppointment}`, {
                                                    codeBouton,
                                                    locale
                                                })}/>}/>
                                            <Route path={routeCustomerLegalNotice}
                                                   element={<CustomerLegalNoticePage/>}/>
                                        </Routes>
                                    </BookingStore>
                                </GiftCardStore>
                            </AccountProvider>
                        </PaymentStore>
                    </AuthStore>
                </ConfigurationStore>
            </CodeboutonProvider>
        </ConfirmProvider>
    </IntlProvider>
}

export const routeGlobalLegalNotice = '/legal-notice';
const GlobalRouter: FC = () => {
    const params = useParams();
    const locale = params['locale'];
    return <IntlProvider locale={locale as SupportedLanguage}>
        <ConfirmProvider>
            <Routes>
                <Route path={routeGlobalLegalNotice} element={<GlobalLegalNoticePage/>}/>
            </Routes>
        </ConfirmProvider>
    </IntlProvider>
}
const IndexPage: FC = () => {

    const intl = useIntl();
    intl.formatMessage({id: "plannerWebsiteUrl"});
    window.location.href = intl.formatMessage({id: "plannerWebsiteUrl"});
    return null;
}

interface RouterProps {

}

export const Router: FC<RouterProps> = () => {
    return <Routes>
        <Route path={"/"} element={<IndexPage/>}/>
        <Route path={"/info/:locale/*"} element={<GlobalRouter/>}/>
        <Route path={"/:codeBouton/:locale/*"}
               element={<CustomerRouter/>}/>
    </Routes>
}
